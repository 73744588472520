<template>
  <div class="pl-4 pt-4 font-light border border-b-0 border-gray-200 dark:border-gray-700 accordion-item">
    <ol class=" font-bold">
      <li style="list-style-type:decimal">
        <h4 style="text-align:justify"><span><strong>Quy định về độ tuổi đăng ký học tập</strong></span></h4>
      </li>
    </ol>

    <p style="text-align:justify"><span>Độ tuổi Khách hàng đăng ký học tập tại IELTS Mentor có 02 đối tượng chính: đối tượng đăng ký học tập trên 15 tuổi; hoặc có thể dưới 15 tuổi khi có sự cho phép, hỗ trợ đăng ký của bố/ mẹ hoặc người giám hộ.</span>
    </p>

    <ol class=" font-bold" start="2">
      <li style="list-style-type:decimal">
        <h4 style="text-align:justify"><span><strong> Quy định đăng ký học tập</strong></span></h4>
      </li>
    </ol>

    <p style="text-align:justify">
      <span>Khách hàng đăng ký học tập thành công tại IELTS Mentor khi đạt đủ các yếu tố sau:</span></p>

    <ul class="ml-10 text-left">
      <li style="list-style-type:disc">
            <span>
                Xác nhận “Phiếu đăng ký học tập” thông qua đường Link được gửi tới số điện thoại Khách hàng đã đăng ký với Trung tâm;
            </span>
        <br>
        <span>
                - Khách hàng được cung cấp tài khoản trên Website của Trung tâm để xác nhận thông tin, lưu trữ Phiếu đăng ký học tập cũng như Điều khoản dịch vụ IELTS Mentor của Trung tâm. Để đảm bảo việc bảo mật thông tin, sau khi đăng ký tài khoản Khách hàng cần xác minh tài khoản bằng mã xác thực đã được hệ thống gửi về Số điện thoại di động của Khách hàng. Nếu không xác minh, Khách hàng sẽ chưa đăng ký thành công học tập tại IELTS Mentor.
            </span>
        <br>
        <span>
                - Trong trường hợp quên mật khẩu, Khách hàng có thể lấy lại qua số điện thoại đã xác thực
            </span>
        <br>

      </li>
    </ul>
    <ul class="ml-10 text-left">
      <li style="list-style-type:disc">
            <span>
                Tổng số tiền Khách hàng đã đóng lớn hơn hoặc bằng 1.200.000 VNĐ. Thanh toán và hoàn thành học phí bằng một trong các hình thức tại mục
            </span>
        <a href="javascript:void(0)"
           style="text-decoration:none"><span
            class="font-bold"><strong><u>QUY ĐỊNH VỀ HÌNH THỨC THANH TOÁN </u></strong></span></a><span> của điều khoản này.</span>
        <br>
        <span>
                - Lưu ý: Số điện thoại Khách hàng ghi trong Nội dung chuyển khoản cần đồng nhất thông tin trên phiếu đăng ký học tập. Số điện thoại này sẽ được sử dụng làm tài khoản để xác nhận thông tin Khách hàng và liên hệ trong suốt quá trình học tập của Khách hàng. 
            </span>
        <br>
        <span>
                - Khi có thay đổi Khách hàng cần liên hệ IELTS Mentor Support Centre để được cập nhật lại thông tin ngay. Nếu số điện thoại không chính xác, Trung tâm sẽ hiểu có thể đang xảy ra rủi ro và có quyền tạm ngưng cung cấp sản phẩm dịch vụ cho Khách hàng.
            </span>
      </li>
    </ul>
    <ol class=" font-bold" start="3">
      <li style="list-style-type:decimal">
        <h4 style="text-align:justify"><span><strong>Lưu ý trong quá trình đăng ký học</strong></span></h4>
      </li>
    </ol>

    <p style="text-align:justify"><span>Trung tâm có quyền từ chối cung cấp dịch vụ cho Khách hàng nếu:</span></p>

    <p style="text-align:justify"><span>- Khách hàng không đủ một trong các điều kiện theo Quy định điều khoản IELTS Mentor của Trung tâm, tại mục 1. Quy định về độ tuổi đăng ký học tập và mục 2. Quy định đăng ký học tập</span><span><strong> </strong></span><span>;</span>
    </p>

    <p style="text-align:justify"><span>- Trung tâm xác minh có yếu tố giả mạo khi Khách hàng và nhân viên kinh doanh lợi dụng chính sách để trục lợi từ IELTS Mentor;</span>
    </p>

    <p style="text-align:justify"><span>
      - IELTS Mentor không nhận được phản hồi từ Khách hàng trong 2 đợt liên hệ xếp lớp dưới đây
    </span>
      <span style="background-color:#ffffff">
          (trong đó, 2 đợt liên hệ trừ các ngày Chủ Nhật, Lễ, Tết theo quy định của Nhà nước).
          <span> Cụ thể:</span>
      </span>
    </p>

    <ul class="ml-10 text-left">
      <li style="list-style-type:disc"><span><strong>Đợt 1</strong></span><span>: 3 cuộc gọi trong 2 ngày liên tục kèm tin nhắn sau mỗi lần gọi qua số điện thoại Khách hàng đăng ký Trung tâm kể từ thời điểm </span><span><strong>tổng học phí đóng tối thiểu bằng giá gốc khóa đầu tiên.</strong></span>
      </li>
      <li style="list-style-type:disc"><span><strong>Đợt 2:</strong></span><span> 1 cuộc gọi kèm tin nhắn sau 4 ngày kể từ cuộc gọi lần thứ 3 của đợt 1.</span>
      </li>
    </ul>
    <div class="ml-5">
      <p><span>+ Trong trường hợp 01 ngày kể từ sau lần liên lạc cuối cùng, nếu không nhận được phản hồi từ Khách hàng, IELTS Mentor coi như Khách hàng đơn phương từ bỏ </span><a
          href="javascript:void(0)"
          style="text-decoration:none"><span class="font-bold"><u>CHÍNH SÁCH HỌC TẬP CỦA KHÁCH HÀNG</u></span></a><span>, và Trung tâm có quyền từ chối cung cấp dịch vụ cho Khách hàng theo thông tin trong </span><a
          href="javascript:void(0)"
          style="text-decoration:none"><span
          class="font-bold"><u>THÔNG TIN ĐĂNG KÝ HỌC TẬP</u></span></a><span>.  </span></p>
      <p><span>+ Trong trường hợp 03 ngày kể từ sau lần liên lạc cuối cùng, Trung tâm hoàn lại 50% học phí cho Khách hàng với điều kiện: Khách hàng đến trực tiếp văn phòng giao dịch thuộc Hệ thống Trung tâm IELTS Mentor mà Khách hàng đã đăng ký học và thông báo với đội ngũ Quản lý tại điểm những lý do không thể tiếp tục đi học được.</span>
      </p>
      <p><span>+ Trong trường hợp sau 03 ngày kể từ sau lần liên lạc cuối cùng, nếu vẫn không nhận được phản hồi từ Khách hàng, IELTS Mentor coi như Khách hàng đã đơn phương từ bỏ </span><a
          href="javascript:void(0)"
          style="text-decoration:none"><span class="font-bold"><u>ĐIỀU KHOẢN DỊCH VỤ IELTS MENTOR</u></span></a><span> và Khách hàng không nhận được bất kỳ quyền lợi gì về sau.</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ieltsmentor_collapse_dieu_khoan_dang_ky_hoc"
}
</script>

<style scoped>
li::marker {
  font-size: 15px;
}
</style>