<template>
  <div class="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 accordion-item">
    <p class="font-bold" style="text-align:justify"><span><strong>Chào mừng Khách hàng đến với Chính sách bảo mật của ECoach</strong></span>
    </p>

    <p style="text-align:justify"><span>Chính sách Bảo mật này là một phần không thể tách rời với điều khoản sử dụng dịch vụ của ECoach. Đồng thời công bố cách thức mà ECoach thu thập, lưu trữ và xử lý thông tin hoặc dữ liệu cá nhân (“Thông tin cá nhân”) của các thành viên sử dụng Website của ECoach: ECoach cam kết bảo mật, nỗ lực hết sức và sử dụng các biện pháp thích hợp để các thông tin mà Khách hàng cung cấp cho ECoach được bảo mật và bảo vệ khỏi sự truy cập trái phép. </span>
    </p>

    <p class="font-bold" style="text-align:justify"><span><strong>Chính sách bảo mật riêng tư của ECoach</strong></span>
    </p>

    <p style="text-align:justify"><span>Khi Khách hàng chia sẻ thông tin với ECoach, ví dụ như xác thực thông tin cá nhân Khách hàng, ECoach có thể làm cho các sản phẩm, dịch vụ đó trở nên tốt hơn nữa, để cung cấp cho Khách hàng nhiều sản phẩm, dịch vụ tiện ích hơn. ECoach muốn Khách hàng biết rõ cách ECoach đang sử dụng thông tin và cách Khách hàng có thể bảo vệ sự riêng tư của mình.</span>
    </p>

    <p style="text-align:justify"><span>Chính sách của ECoach về bảo mật thông tin bao gồm:</span></p>

    <ul class="ml-10">
      <li style="list-style-type:disc"><span class="float-left">Loại thông tin ECoach thu thập và lý do thu thập.</span>
      </li>
      <li style="list-style-type:disc"><span class="float-left"> Cách ECoach sử dụng thông tin đó.</span></li>
      <li style="list-style-type:disc"><span class="float-left"> Các lựa chọn mà ECoach cung cấp, bao gồm cả cách truy cập và cập nhật thông tin.</span>
      </li>
    </ul>

    <ol class="ml-9 font-bold">
      <li style="list-style-type:decimal">
        <h2 style="text-align:justify"><span><strong>Thông tin ECoach thu thập</strong></span></h2>
      </li>
    </ol>

    <p style="text-align:justify"><span>ECoach thu thập thông tin của Khách hàng như: Thông tin cá nhân, Thông tin liên lạc, Thông tin về thanh toán.</span>
    </p>

    <p style="text-align:justify"><span>ECoach thu thập thông tin theo các cách sau:</span></p>

    <ul class="ml-10">
      <li style="list-style-type:disc"><span class="float-left"> Thông tin mà Khách hàng cung cấp cho ECoach. </span>
      </li>
    </ul>

    <p style="text-align:justify">
        <span>
            Ví dụ: Khi Khách hàng đăng ký tài khoản bằng số điện thoại trong Nội dung chuyển khoản, ECoach sẽ yêu cầu xác thực các thông tin mà Khách hàng đã cung cấp cho đội ngũ Tư vấn viên của ECoach:
        </span>
      <br>
      <span class="ml-5">
            - Thông tin cá nhân: Họ và tên, ngày tháng năm sinh, số căn cước công dân;
        </span>
      <br>
      <span class="ml-5">
            - Thông tin liên lạc:  Địa chỉ email, số điện thoại;
        </span>
      <br>
      <span class="ml-5">
            - Thông tin học tập: Chi nhánh đăng ký học, các khóa học đăng ký;
        </span>
      <br>
      <span class="ml-5">
            - Thông tin thanh toán: Tổng học phí cần thanh toán; Hình thức hoàn thành học phí.
        </span>
    </p>
    <ul class="ml-10">
      <li style="list-style-type:disc"><span class="float-left">Thông tin ECoach thu thập từ quá trình Khách hàng sử dụng sản phẩm, dịch vụ của ECoach. </span>
      </li>
    </ul>

    <p style="text-align:justify"><span>Ví dụ: Khách hàng sử dụng sản phẩm, dịch vụ trên website của ECoach; tương tác với các nội dung trên fanpage của ECoach hay liên hệ với ECoach qua đầu số</span><span><strong> 1900 4782</strong></span><span>. Thông tin nhật ký hệ thống điện thoại như số điện thoại của Khách hàng, số chủ gọi, số chuyển tiếp, thời gian và ngày gọi và loại cuộc gọi.</span>
    </p>

    <ol class="ml-9 font-bold" start="2">
      <li style="list-style-type:decimal">
        <h2 style="text-align:justify"><span><strong>Cách ECoach sử dụng thông tin thu thập được</strong></span></h2>
      </li>
    </ol>

    <p style="text-align:justify"><span>ECoach sử dụng thông tin thu thập được để cung cấp, duy trì, bảo vệ và cải thiện các sản phẩm, dịch vụ. ECoach cũng sử dụng thông tin này nhằm cung cấp cho Khách hàng nội dung phù hợp: như cung cấp cho Khách hàng các thông tin về sản phẩm, dịch vụ liên quan phù hợp hơn.</span>
    </p>

    <p style="text-align:justify"><span>Khi Khách hàng liên hệ với ECoach, ECoach ghi lại nội dung giao tiếp của Khách hàng nhằm giúp giải quyết mọi vấn đề mà Khách hàng có thể đang gặp phải. ECoach có thể sử dụng địa chỉ email, Số điện thoại của Khách hàng để thông báo cho Khách hàng biết về sản phẩm, dịch vụ của ECoach, chẳng hạn như cho Khách hàng biết về các thay đổi hoặc cải tiến sắp tới.</span>
    </p>

    <p style="text-align:justify"><span>ECoach sẽ đề nghị Khách hàng đưa ra sự đồng ý cho phép trước khi sử dụng thông tin cho một mục đích nào đó khác với các mục đích đã được nêu trong Chính sách về sự riêng tư này.</span>
    </p>

    <ol class="ml-9 font-bold" start="3">
      <li style="list-style-type:decimal">
        <h2 style="text-align:justify">
          <span><strong>Truy cập và cập nhật thông tin cá nhân của Khách hàng</strong></span></h2>
      </li>
    </ol>

    <p style="text-align:justify"><span>Bất cứ khi nào Khách hàng sử dụng sản phẩm, dịch vụ của ECoach, ECoach cố gắng cung cấp cho Khách hàng quyền truy cập thông tin cá nhân của Khách hàng. Nếu thông tin đó không đúng, ECoach cố gắng cung cấp cho Khách hàng các cách để cập nhật nhanh chóng hoặc xóa thông tin đó – trừ khi ECoach phải giữ thông tin đó cho mục đích pháp lý hoặc kinh doanh hợp pháp. Khi cập nhật thông tin cá nhân của Khách hàng, ECoach có thể yêu cầu Khách hàng xác minh danh tính của mình trước khi ECoach xử lý yêu cầu của Khách hàng.</span>
    </p>

    <p style="text-align:justify"><span>ECoach có thể từ chối các yêu cầu lặp lại nhiều lần một cách bất hợp lý, đòi hỏi các nỗ lực không tương xứng về kỹ thuật, gây rủi ro đến sự riêng tư của người khác hoặc quá phi thực tế.</span>
    </p>

    <ol class="ml-9 font-bold" start="4">
      <li style="list-style-type:decimal">
        <h2 style="text-align:justify"><span><strong>Thông tin ECoach chia sẻ</strong></span></h2>
      </li>
    </ol>

    <p style="text-align:justify"><span>ECoach không chia sẻ thông tin cá nhân với công ty, tổ chức và cá nhân bên ngoài ECoach ngoại trừ 2 trường hợp:</span>
    </p>

    <ul class="text-left">
      <li class="ml-10" style="list-style-type:disc"><span> Vì lý do pháp lý: </span><span style="color:#222222">ECoach sẽ chia sẻ thông tin cá nhân với các cơ quan, tổ chức có liên quan nếu việc cung cấp thông tin là cần thiết và hợp lý vì:</span>

        <ul class="ml-10">
          <li style="list-style-type:circle"><span>Theo chỉ đạo/yêu cầu của các Cơ quan chức năng có thẩm quyền và đảm bảo đúng theo quy định của Pháp luật về bí mật thông tin cá nhân.</span>
          </li>
          <li style="list-style-type:circle"><span>Đáp ứng bất kỳ luật pháp, quy định, quy trình pháp lý hiện hành nào hoặc yêu cầu có thể cưỡng chế thi hành của chính quyền.</span>
          </li>
          <li style="list-style-type:circle"><span> Thực thi Điều khoản sản phẩm, dịch vụ được áp dụng, kể cả việc điều tra những vi phạm tiềm tàng.</span>
          </li>
          <li style="list-style-type:circle"><span> Phát hiện, ngăn ngừa hoặc theo cách khác xử lý các vấn đề về gian lận, an ninh hoặc kỹ thuật.</span>
          </li>
          <li style="list-style-type:circle"><span> Bảo vệ các quyền, tài sản hoặc sự an toàn của ECoach, người dùng của ECoach hoặc công chúng không bị tổn hại khi cần thiết hoặc được cho phép bởi luật pháp.</span>
          </li>
        </ul>
      </li>
    </ul>

    <ul class="text-left">
      <li class="ml-10" style="list-style-type:disc"><span>Có sự đồng ý của Khách hàng: ECoach sẽ chia sẻ thông tin cá nhân với công ty, tổ chức hoặc cá nhân bên ngoài ECoach, việc chia sẻ này chỉ nhằm mục đích nghiên cứu và đưa ra các sản phẩm và dịch vụ tốt hơn tới từng Khách hàng. Nếu Khách hàng không muốn chia sẻ những thông tin trên, hãy liên hệ qua Tổng đài Chăm sóc Khách hàng 1900 4782 với chúng tôi.</span>
      </li>
    </ul>

    <ol class="ml-9 font-bold" start="5">
      <li style="list-style-type:decimal">
        <h2 style="text-align:justify"><span><strong>An ninh thông tin</strong></span></h2>
      </li>
    </ol>

    <p style="text-align:justify"><span>ECoach nỗ lực làm việc để bảo vệ ECoach và người dùng của mình không bị truy cập trái phép, sửa đổi trái phép, tiết lộ hoặc phá hoại thông tin mà ECoach nắm giữ. Cụ thể:</span>
    </p>

    <ul class="text-left">
      <li class="ml-10" style="list-style-type:disc"><span>ECoach thu thập, lưu trữ và xử lý thông tin của mình kể cả biện pháp an ninh vật chất nhằm bảo vệ chống truy cập trái phép vào hệ thống.</span>
      </li>
      <li class="ml-10" style="list-style-type:disc"><span>ECoach chỉ cho phép nhân viên và người đại diện của ECoach truy cập thông tin cá nhân vì họ là những người cần biết thông tin này để xử lý các thông tin đó cho ECoach và họ phải chịu sự chi phối của nghĩa vụ bảo mật nghiêm ngặt trong hợp đồng và có thể bị kỷ luật hoặc chấm dứt hợp đồng nếu họ không tuân theo các nghĩa vụ đó.</span>
      </li>
    </ul>

    <ol class="ml-9 font-bold" start="6">
      <li style="list-style-type:decimal">
        <h2 style="text-align:justify"><span><strong>Các thay đổi</strong></span></h2>
      </li>
    </ol>

    <p style="text-align:justify"><span>Chính sách của ECoach về sự riêng tư có thể thay đổi trong từng thời điểm. ECoach sẽ đăng bất kỳ thay đổi nào đối với chính sách trên trang này và thông báo nổi bật với những thay đổi quan trọng. </span>
    </p>

    <p style="text-align:justify"><span>ECoach giữ riêng tư và an toàn cho thông tin cá nhân của Khách hàng đồng thời, Khách hàng nắm quyền kiểm soát.</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "ecoach_collapse_chinh_sach_bao_mat"
}
</script>

<style scoped>
li::marker {
  font-size: 15px;
}
</style>