<template>
  <div class="pt-4 pl-4 font-light border border-t-0 border-gray-200 dark:border-gray-700 accordion-item">
    <ol class=" font-bold">
      <li style="list-style-type:decimal">
        <h4 style="text-align:justify">
          <span><strong>Nộp học phí trực tiếp bằng tiền mặt tại hệ thống Trung tâm ECoach</strong></span></h4>
      </li>
    </ol>

    <p style="text-align:justify"><span>Khi đến văn phòng giao dịch thuộc văn phòng ECoach (sau đây viết tắt là ECoach) thực hiện việc thanh toán, Khách hàng sẽ được nhân viên ECoach hướng dẫn các bước thanh toán. </span>
    </p>

    <p style="text-align:justify"><span>Địa điểm: Số 70 đường bờ sông Sét, Hoàng Mai, Hà Nội.</span></p>

    <p style="text-align:justify"><span>Thời gian nhận thanh toán của Khách hàng bằng tiền mặt: từ 14h00 đến 21h00 các ngày từ thứ 2 tới thứ 7 hàng tuần (trừ ngày lễ, tết theo quy định Nhà nước).</span>
    </p>

    <ol class=" font-bold" start="2">
      <li style="list-style-type:decimal">
        <h4 style="text-align:justify"><span><strong>Thanh toán chuyển khoản</strong></span></h4>
      </li>
    </ol>

    <p class="font-bold" style="text-align:justify"><span><strong>2.1. Chuyển tiền/ chuyển khoản theo thông tin chi tiết ECoach cung cấp:</strong></span>
    </p>

    <div>
      <p><span>- Ngân hàng thương mại cổ phần Quân đội </span><span class="font-bold"><strong>MBbank</strong></span></p>
      <p><span class="font-bold"><strong>- Chủ tài khoản</strong></span><span>: Hồ Văn Hoàn</span></p>
      <p><span class="font-bold"><strong>- Số tài khoản</strong></span><span>: 4666999666999</span></p>
      <p><span class="font-bold"><strong>- Nội dung chuyển khoản</strong></span><span>: Số điện thoại  Họ và Tên Khách hàng</span>
      </p>
    </div>

    <p class="font-bold italic" style="text-align:justify">
      <span><strong><em>Ví dụ: 0123456789 Nguyen Van A</em></strong></span></p>

    <p style="text-align:justify"><span>Số điện thoại trong Nội dung chuyển khoản sẽ được sử dụng để xác nhận thông tin chuyển khoản và gọi điện xếp lớp.</span>
    </p>

    <p class="font-bold" style="text-align:justify"><span><strong>2.2. Hoàn tất thông tin sau tin nhắn xác nhận chuyển tiền</strong></span>
    </p>

    <p style="text-align:justify">
      <span>
      ECoach sẽ nhắn tin tới số điện thoại Khách hàng cung cấp trong Nội dung chuyển khoản để xác nhận thông tin chuyển tiền với Khách hàng muộn nhất trong vòng 4h (theo giờ hành chính) sau khi nhận được tiền. Trường hợp không nhận được tin nhắn, Khách hàng vui lòng liên hệ qua Tổng đài
    </span>
      <span class="font-bold">
      <span>
                <strong>1900 4782</strong>
                </span>
      <span> để được xử lý thông tin kịp thời.</span>
              </span>
    </p>

    <p style="text-align:justify"><span>ECoach không chịu trách nhiệm nếu trong quá trình chuyển khoản sai thông tin. Trong một số tình huống cụ thể, Khách hàng có thể nhờ phía Ngân hàng thực hiện chuyển khoản hoặc Ngân hàng thụ hưởng của ECoach để kiểm tra đối chứng cần thiết. Ngay khi nhận được xác nhận từ Ngân hàng, ECoach sẽ tiến hành thủ tục tiếp theo cho Khách hàng.</span>
    </p>

    <ol class=" font-bold" start="3">
      <li style="list-style-type:decimal">
        <h4 style="text-align:justify"><span><strong>Thanh toán qua thẻ</strong></span></h4>
      </li>
    </ol>

    <p style="text-align:justify"><span>Khi đến văn phòng giao dịch thuộc Hệ thống Trung tâm ECoach (sau đây viết tắt là ECoach) thực hiện việc thanh toán, Khách hàng sẽ được nhân viên ECoach hướng dẫn các bước thanh toán qua thẻ. </span>
    </p>

    <p style="text-align:justify"><span>Địa điểm: Số 70 đường bờ sông Sét, Hoàng Mai, Hà Nội.</span></p>

    <p style="text-align:justify"><span>Thời gian nhận thanh toán của Khách hàng bằng tiền mặt: từ 14h00 đến 21h00 các ngày từ thứ 2 tới thứ 7 hàng tuần (trừ ngày lễ, tết theo quy định Nhà nước).</span>
    </p>

    <ol class=" font-bold" start="4">
      <li style="list-style-type:decimal">
        <h4 style="text-align:justify"><span><strong>Lưu ý sau khi thanh toán</strong></span></h4>
      </li>
    </ol>

    <p><span>- ECoach không chấp nhận và hỗ trợ xử lý các trường hợp nếu Khách hàng thanh toán bằng hình thức khác với 3 (ba) hình thức trong mục </span><a
        href="javascript:void(0)"
        style="text-decoration:none"><span
        class="font-bold"><strong><u>QUY ĐỊNH VỀ HÌNH THỨC THANH TOÁN</u></strong></span></a><span><strong> </strong></span><span> của điều khoản này.</span>
    </p>
    <p><span>- Khách hàng sẽ được cung cấp:</span></p>
    <p class="pl-5"><span class="font-bold"><strong>+ Phiếu đăng ký học tập tại ECoach: </strong></span><span>Sau khi đăng ký học tập thành công;</span>
    </p>
    <p class="pl-5"><span class="font-bold"><strong>+ Hóa đơn:</strong></span><span> Nhận qua gmail Khách hàng đăng ký Trung tâm (nếu Khách hàng thanh toán không tại Trung tâm) hoặc trực tiếp tại Trung tâm ECoach sau mỗi lần Khách hàng thanh toán thành công.</span>
    </p>
    <p><span class="font-bold"><strong>- Hóa đơn và Phiếu đăng ký học tập tại ECoach</strong></span><span> được sử dụng để giải quyết các vấn đề liên quan đến quyền lợi trong quá trình học tập bao gồm:</span>
    </p>
    <p class="pl-5"><span>+ Xếp lớp học theo khóa học, thời gian học đã đăng ký</span></p>
    <p class="pl-5"><span>+ Chuyển nhượng khóa học (nếu có);</span></p>
    <p class="pl-5"><span>+ Hoàn học phí (nếu có);</span></p>
    <p class="pl-5"><span>+ Bảo lưu kết quả học tập (nếu có);</span></p>
    <p class="pl-5"><span>+ Bảo hành kết quả học tập.</span></p>

    <p style="text-align:justify"><span>Khách hàng nhận hồ sơ và có trách nhiệm lưu giữ. Trường hợp không nhận được Hóa đơn và Phiếu đăng ký học tập tại ECoach, vui lòng liên hệ Tổng đài </span><span
        class="font-bold"><strong>1900 4782 </strong></span><span>để được hỗ trợ.</span></p>

    <p> </p>
  </div>
</template>

<script>
export default {
  name: "ecoach_collapse_hinh_thuc_thanh_toan"
}
</script>

<style scoped>
li::marker {
  font-size: 15px;
}
</style>