<script>
import {formatVND} from "../../../../core/filters";
import {MONEY, PERCENT, PERIODIC_PROMOTIONS, OTHER_SCHOOL, FLASH_SALE} from "@/core/config/voucher_code/voucher_code";
import {DIGITAL, SCHOOL_LINK} from "../../../../core/config/voucher_code/voucher_code";

export default {
  name: "VoucherDialog",
  methods: {
    formatVND,
    openDetail(item) {
      this.$emit('openDetail', item)
    },
    pickPromotions(voucher,isCancel = false, isUseGift = false) {
      if (+this.type === PERIODIC_PROMOTIONS || +this.type === DIGITAL || +this.type === 2) {
        this.allCode[this.type].forEach((code) => {
          code.selected = false
          if(!isUseGift && !voucher.isGift && code.isGift){
            code.isUseGift = true;
          }
        })
      } else {
        voucher.selected = !voucher.selected
      }
      let selectedAmount = this.allCode[this.type].filter((e)=>e.selected).length
      for (let key in this.allCode) {
        // if(+this.type === SCHOOL_LINK){
        this.allCode[key].forEach((code) => {
          if(!isCancel){
            code.hidden = true
          }
          if(selectedAmount === 0 && isCancel){
            code.hidden = false
          }
        })
        // }
        if (+key !== +this.type) {
          this.allCode[key].forEach((code) => {
            if(!isCancel){
              code.hidden = true
            }
            if(selectedAmount === 0 && isCancel){
              code.hidden = false
            }
          })
        }
      }
      this.$emit('pickPromotions');
    },
    applyVoucher(voucher, isUseGift = true) {
      //ẩn hết các voucher và quà
      for (let key in this.allCode) {
        this.allCode[key].forEach((code) => {
          code.hidden = true
        });
      }
       //if voucher là digital hoặc định kỳ thì chọn/huy tat cả
      if (+this.type === PERIODIC_PROMOTIONS || +this.type === DIGITAL) {
        this.allCode[this.type].forEach((code) => {
          code.selected = !code.selected
          if(!voucher.isGift && code.isGift){
            code.isUseGift = true
          }
        });

      } else {
        voucher.selected = !voucher.selected
        if (voucher.isGift) {
          voucher.isUseGift = true
        }
      }
      this.$emit('pickPromotions');

      if (this.type == OTHER_SCHOOL || this.type == FLASH_SALE) {
        this.allCode[this.type].forEach((code) => {
          code.hidden = false
          if(code.isGift && this.type === FLASH_SALE){
            code.selected = true;
            code.isUseGift = true
          }
        });
        this.$emit('pickPromotions');
      }
    },
    changeVoucher(voucher) {
      if (voucher.program_id !== PERIODIC_PROMOTIONS && voucher.program_id !== DIGITAL) {
        voucher.selected = true;
        voucher.isUseGift = false;
        voucher.hidden = false;
        for (let key in this.allCode) {
          this.allCode[key].forEach((code) => {
            code.hidden = true
          });
        }
        if (voucher.program_id == OTHER_SCHOOL || voucher.program_id == FLASH_SALE) {
          this.allCode[voucher.program_id].forEach((code) => {
            code.hidden = false
          });
        }
        this.$emit('pickPromotions');
        return;
      }
      
        this.allCode[this.type].forEach((code) => {
          code.selected = !code.selected
            code.isUseGift = false;
        });
        for (let key in this.allCode) {
          this.allCode[key].forEach((code) => {
            code.hidden = true
          });
        }
        this.$emit('pickPromotions');
    },
    changeIsUseGift(voucherId) {
      return this.arrayVoucher.includes(voucherId) ? 'Đã quy đổi voucher' : 'Đã áp dụng quà tặng';
    }

  },
  props: {
    listCode: {
      type: Array,
      default() {
        return []
      }
    },
    colorCode: {
      type: String,
      default() {
        return '';
      }
    },
    allCode: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: Number,
      default() {
        return null
      }
    },
    arrayVoucher: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      MONEY: MONEY,
      PERCENT: PERCENT
    }
  },

}
</script>

<template>
  <div>
    <div v-if="listCode.length > 0">
      <div
          class="d-flex justify-content-between row-promotion w-100 voucher"
          :style="{ border: `solid 1px ${colorCode}` }"
          v-for="(voucher, index) in listCode"
          :key="'d'+index"
      >
        <div class="col-md-2 voucher-image">
          <div style="width:107px ; height:100%"
               :style="{ border: `solid 1px ${colorCode}`, backgroundColor : `${colorCode}`}"
               class=" d-flex align-items-center justify-content-center icon_image_voucher"
          >
            <div class="mt-4">
              <img v-if="!voucher.isGift" src="../../../../../public/media/images/voucher.png" alt="">
              <img v-if="voucher.isGift" src="../../../../../public/media/images/gift.png" alt="">
              <p class="mt-2" style="color: white ; font-size: 13px">{{voucher.isGift?'Quà tặng':'Voucher'}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 voucher-detail text-left mr-0 h-100 flex align-items-center mt-3">
          <span style="font-size: 14px;font-weight: bold">{{ voucher.nameCode }}</span>
          <div style="width: fit-content;font-size: 12px;padding: 5px;border-radius: 4px;color: white" class="mt-2 voucher_value"
               :style="{ backgroundColor : `${colorCode}`}" v-if="+voucher.value > 0">
                            <span class="discount" v-if="voucher.unit == MONEY">
                         <span v-if="!voucher.isGift">Giảm: </span>
                              <span v-else>Giá trị quy đổi voucher: </span>
                                {{ voucher.value | formatVND }}
                        </span>
            <span class="discount" v-if="voucher.unit == PERCENT">
                         <span v-if="!voucher.isGift">Giảm: </span>
                              <span v-else>Giá trị quy đổi voucher: </span>
                           {{ voucher.value }}%
                        </span>
          </div>
          <div class="mt-2">
            <img src="../../../../../public/media/images/time.png" alt="">
            <span class="end_date mt-3" style="position: relative;top: 1px"
                  v-if="voucher.endDate"> Hiệu lực đến: {{ voucher.endDate }} </span>
            <span class="end_date mt-3" style="position: relative;top: 1px"
                  v-if="!voucher.endDate"> Chưa có thời hạn </span>
          </div>
          <p @click="openDetail(voucher)" style="color:#2045C9;cursor: pointer;margin-top: 5px">Xem chi
            tiết</p>
        </div>
        <div class="col-md-3 voucher-action d-flex align-items-center justify-content-center" v-if="!voucher.isGift">
          <button v-if="!voucher.selected" type="button" class="btn btn-outline-primary select"
                  :class="{'btn-outline-secondary':voucher.hidden}" :disabled="voucher.hidden"
                  @click="applyVoucher(voucher)">Áp dụng
          </button>
          <button v-if="voucher.selected" type="button" @click="pickPromotions(voucher,true)" class="btn btn-danger">Hủy áp
            dụng
          </button>
        </div>
        <div class="col-md-3 voucher-action flex align-items-center text-center" v-else>
          <button v-if="!voucher.selected" type="button" class="btn btn-primary "
                  :class="{'btn-secondary':voucher.hidden}" :disabled="voucher.hidden"
                  @click="applyVoucher(voucher)">Áp dụng
          </button>
          <br class="hidden br">
          <button  v-if="!voucher.selected && +voucher.value > 0" type="button" class="btn btn-outline-primary mt-3 "
                  :class="{'btn-outline-secondary':voucher.hidden}" :disabled="voucher.hidden"
                  @click="changeVoucher(voucher)">Quy đổi voucher
          </button>
          <p v-if="voucher.selected" class="mt-2 mb-2">{{voucher.isUseGift ? changeIsUseGift(voucher.id) :'Đã quy đổi voucher'}}</p>
          <button v-if="voucher.selected" type="button" @click="pickPromotions(voucher,true)" class="btn btn-danger">Hủy áp
            dụng
          </button>
        </div>
      </div>
    </div>
    <div v-else class="no-promotion">
      <p>Không có voucher</p>
    </div>
  </div>
</template>

<style scoped>
@media only screen and (max-width: 767px) {
  .voucher{
    display: block !important;
    padding-bottom: 10px !important;
  }
  .voucher-image{
     padding: 0 !important;
  }
  .icon_image_voucher{
    width: 100% !important;
    height: 85px !important;
  }
  .voucher-detail{
    text-align: center !important;
  }
  .voucher_value{
    width: 100% !important;
  }
  .br{
    display: block !important;
  }
}
</style>