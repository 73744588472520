export class Code_Model {
    constructor(program_id=1,branch_id,course,course_ids,gift,id,is_center,type,value,name, typeCode, startDate, endDate, description,unit,amount,isGift = false,isUseGift = false) {
        this.nameCode = name;
        this.typeCode = typeCode;
        this.startDate = startDate;
        this.endDate = endDate;
        this.description = description;
        this.unit = unit;
        this.isGift = isGift;
        this.amount = amount;
        this.selected = false;
        this.hidden = false;
        this.isUseGift = isUseGift;
        // data cu
        this.branch_id = branch_id
        this.course = course
        this.course_ids= course_ids
        this.end_date = this.endDate
        this.gift = gift
        this.id = id
        this.is_center = is_center
        this.is_flash_sale = typeCode
        this.name = this.nameCode
        this.start_date = this.startDate
        this.type = type
        this.value = value
        this.program_id = program_id
    }
}