var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pl-4 pt-4 font-light border border-t-0 border-gray-200 dark:border-gray-700 accordion-item"},[(_vm.contract && _vm.contract.tuition_payment_id == 1)?_c('div',{attrs:{"id":"thanh-toan-toan-bo-hoc-phi"}},[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.contract && _vm.contract.tuition_payment_id == 2)?_c('div',{attrs:{"id":"tra-gop-ngan-hang"}},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26)]):_vm._e(),(_vm.contract && _vm.contract.tuition_payment_id == 3)?_c('div',{attrs:{"id":"tra-gop-trung tam"}},[_vm._m(27),_vm._m(28),_vm._m(29),_vm._m(30),_vm._m(31),_vm._m(32),_vm._m(33),_vm._m(34),_vm._m(35),_vm._m(36),_vm._m(37),_vm._m(38),_vm._m(39),_vm._m(40),_vm._m(41),_vm._m(42),_vm._m(43),_vm._m(44),_vm._m(45),_vm._m(46),_vm._m(47),_vm._m(48),_vm._m(49),_vm._m(50),_vm._m(51),_vm._m(52),_vm._m(53),_vm._m(54),_vm._m(55),_vm._m(56)]):_vm._e(),(_vm.contract && _vm.contract.tuition_payment_id == 4)?_c('div',{attrs:{"id":"thanh-toan-chia-theo-giai-doan"}},[_vm._m(57)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("Thời gian thanh toán: Hoàn thành 100% tổng giá trị hợp đồng trong tháng (Tại tháng có ngày đóng học phí lần 1).")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("Lưu ý: Ngày đóng học phí lần 1 được tính khi tổng số tiền Khách hàng đã đóng lớn hơn hoặc bằng 1.200.000 VNĐ.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold"},[_c('span',[_c('strong',[_vm._v("Thanh toán trả góp với Ngân Hàng qua Payoo")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("- Thanh toán trả góp với Ngân hàng qua Payoo là hình thức thanh toán học phí bằng thẻ tín dụng của Ngân Hàng có liên kết với Payoo. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("- Trong đó Payoo đóng vai trò là đơn vị trung gian về công nghệ kết nối Khách hàng có thẻ tín dụng Ngân hàng với Trung tâm và Khách hàng thực hiện trả góp với ngân hàng theo kỳ hạn tùy chọn và lãi suất tương ứng.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold",staticStyle:{"text-align":"justify"}},[_c('span',[_c('strong',[_vm._v("1. Đối tượng áp dụng")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold",staticStyle:{"text-align":"justify"}},[_c('span',[_c('strong',[_vm._v("- Khách hàng đã có thẻ tín dụng Ngân hàng")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("+ Yêu cầu đối với thẻ tín dụng: Nằm trong danh sách 25 Ngân hàng liên kết với Payoo.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("+ Danh sách 25 Ngân hàng liên kết Payoo gồm: Techcombank, ACB, BIDV, Vietcombank, Vietinbank, Sacombank, TPBank, VIB, MSB, MB, OCB, SCB, Seabank, Pvcombank, Citibank, Eximbank, FECredit, HDBank, HSBC, Kiên Long Bank, Lien Viet Post Bank, Nam A Bank, SHB, Shinhan Bank, Viet Capital Bank .")]),_c('br'),_c('br'),_c('span',{staticClass:"font-bold"},[_c('strong',[_vm._v("- Khách hàng chưa mở thẻ tín dụng Ngân hàng")])]),_c('br'),_c('span',[_vm._v("Trung tâm hỗ trợ mở thẻ tín dụng của Ngân hàng Sacombank.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',{staticClass:"ml-10",staticStyle:{"list-style-type":"disc"}},[_c('span',[_c('strong',[_vm._v("Đối tượng mở thẻ")])]),_c('br'),_c('span',[_vm._v("Áp dụng mở thẻ đối với Khách hàng hoặc Bố/Mẹ khách hàng đang làm việc/ sinh sống tại các tỉnh thành phố: Hà Nội, Hồ Chí Minh, Thanh Hóa, Hà Tĩnh,... ")])]),_c('li',{staticClass:"ml-10",staticStyle:{"list-style-type":"disc"}},[_c('span',[_c('strong',[_vm._v(" Hồ sơ mở thẻ")])]),_c('ul',[_c('li',{staticStyle:{"list-style-type":"circle"}},[_c('span',[_vm._v("Căn cước công dân gắn chip hoặc chứng minh thư & Sổ hộ khẩu photo;")])]),_c('li',{staticStyle:{"list-style-type":"circle"}},[_c('span',[_vm._v("Màn hình tin nhắn lương 03 tháng gần nhất;")])]),_c('li',{staticStyle:{"list-style-type":"circle"}},[_c('span',[_vm._v(" Form đăng ký trả góp với Ngân hàng Sacombank.")])]),_c('li',{staticStyle:{"list-style-type":"circle"}},[_c('span',[_vm._v("Đối với Khách hàng có sổ hộ khẩu Tình cần có 1 trong 4 giấy tờ sau:")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"80px","text-align":"justify"}},[_c('span',[_vm._v("+ Tạm trú tạm vắng")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"80px","text-align":"justify"}},[_c('span',[_vm._v("+ Hoá đơn điện")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"80px","text-align":"justify"}},[_c('span',[_vm._v("+ Mã PE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"80px","text-align":"justify"}},[_c('span',[_vm._v("+ Hợp đồng thuê nhà;")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold",staticStyle:{"text-align":"justify"}},[_c('span',[_c('strong',[_vm._v("2. Chính sách thanh toán trả góp")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("- Thanh toán trả góp chỉ áp dụng cho khoản tiền từ 2.000.000 VNĐ (hai triệu đồng) trở lên;")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("- Sau khi đăng ký khóa học thành công bằng hình  thức thanh toán trả góp với Ngân Hàng qua Payoo Khách hàng có thể sử dụng khóa học như bình thường (như thanh toán các hình thức khác);")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("- Thời hạn đăng ký trả góp: 6, 9 hoặc 12 tháng.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold",staticStyle:{"text-align":"justify"}},[_c('span',[_c('strong',[_vm._v("3. Điều kiện học phí ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("- Khách hàng cần đóng số tiền tối thiểu bằng 20% tổng giá trị hợp đồng trong lần thanh toán đầu tiên và hoàn thành đầy đủ học phí trong tháng (Tại tháng có ngày đóng học phí lần 1).")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("Lưu ý: Ngày đóng học phí lần 1 được tính khi tổng số tiền Khách hàng đã đóng lớn hơn hoặc bằng 1.200.000 VNĐ.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold",staticStyle:{"text-align":"justify"}},[_c('span',[_c('strong',[_vm._v("4. Mức phí")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("- Phí và lãi suất chuyển đổi trả góp là 9% giá trị quẹt thẻ trong đó:")]),_c('br'),_c('br'),_c('span',[_vm._v("+ Trung tâm hỗ trợ phí lãi suất trả góp là 6,5% giá trị quẹt thẻ;")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("+ Khách hàng chỉ cần thanh toán phần phí lãi suất trả góp còn lại, tương đương với 2.5% giá trị quẹt thẻ.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold",staticStyle:{"text-align":"justify"}},[_c('span',[_c('strong',[_vm._v("5. Các bước đăng ký")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',{staticClass:"font-bold"},[_c('strong',[_c('u',[_vm._v("Cách 1")])])]),_c('span',[_vm._v(": Đến trực tiếp các Trung tâm để được chuyên viên tư vấn tại văn phòng hướng dẫn;")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',{staticClass:"font-bold"},[_c('strong',[_c('u',[_vm._v("Cách 2")])])]),_c('span',[_vm._v(": Nếu có nhu cầu, hãy báo với tư vấn viên. Đội ngũ chuyên viên chăm sóc Khách hàng sẽ hướng dẫn và giải đáp các thắc mắc của Khách hàng.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("Thanh toán trả góp qua Trung tâm (sau đây gọi là “thanh toán trả góp”) là hình thức thanh toán học phí bằng hợp đồng với Trung tâm IELTS Mentor, sau đó thực hiện trả góp với Trung tâm theo kỳ hạn và lãi suất tương ứng.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold",staticStyle:{"text-align":"justify"}},[_c('span',[_c('strong',[_vm._v("1. Đối tượng áp dụng:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("Toàn bộ Khách hàng đang sinh sống/học tập và làm việc trên lãnh thổ Việt Nam:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Từ 22 - 60 tuổi: bản thân Khách hàng;")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Ủy quyền bố/ mẹ nếu là học sinh hoặc sinh viên.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold",staticStyle:{"text-align":"justify"}},[_c('span',[_c('strong',[_vm._v("2. Chính sách thanh toán trả góp")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Thanh toán trả góp chỉ áp dụng cho khoản tiền từ 3.000.000 VNĐ (ba triệu đồng) trở lên;")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Sau khi đăng ký khóa học thành công bằng hình thức thanh toán trả góp, Khách hàng có thể sử dụng khóa học như bình thường (như Khách hàng thanh toán các hình thức khác);")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Việc trả góp sau đó là nghĩa vụ của Khách hàng với bên Trung tâm, Khách hàng cần đảm bảo thanh toán đúng hạn hàng tháng để đảm bảo quá trình học tập và quyền lợi của Khách hàng. Trường hợp không đúng hạn, Trung tâm có quyền tạm dừng cung cấp dịch vụ với Khách hàng ở khóa học tiếp theo.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold",staticStyle:{"text-align":"justify"}},[_c('span',[_c('strong',[_vm._v("3. Hồ sơ đăng ký trả góp Trung tâm:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Căn cước công dân gắn chip hoặc Chứng minh nhân dân/Căn cước công dân không gắn chip kèm sổ hộ khẩu còn giá trị;")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Form đăng ký trả góp với Trung tâm.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',{staticClass:"font-bold"},[_c('strong',[_vm._v("4. Điều kiện học phí")])]),_c('span',[_vm._v(": Khách hàng cần đóng số tiền tối thiểu bằng giá gốc của khóa đầu tiền trong combo đăng ký để hoàn thành hồ sơ trả góp.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold"},[_c('span',[_c('strong',[_vm._v("5. Mức phí")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Số tiền trả góp hàng tháng: 2.000.000 VNĐ/ tháng. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Phí và lãi suất trả góp: 2% giá trị đăng ký trả góp. IELTS Mentor hỗ trợ 100% chi phí lãi suất cho Khách hàng")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold"},[_c('span',[_c('strong',[_vm._v("6. Các bước đăng ký")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',{staticClass:"font-bold"},[_c('strong',[_c('u',[_vm._v("Cách 1")])])]),_c('span',[_vm._v(": Đến trực tiếp các văn phòng thuộc hệ thống IELTS Mentor để được chuyên viên tư vấn tại văn phòng hướng dẫn;")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',{staticClass:"font-bold"},[_c('strong',[_c('u',[_vm._v("Cách 2")])])]),_c('span',[_vm._v(": Nếu có nhu cầu, hãy báo với tư vấn viên hoặc trực tiếp qua kênh IELTS Mentor Support Centre của IELTS Mentor. Đội ngũ chuyên viên chăm sóc Khách hàng của IELTS Mentor sẽ hướng dẫn và giải đáp các thắc mắc của Khách hàng.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold"},[_c('span',[_c('strong',[_vm._v("7. Thời hạn thanh toán Trung tâm")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Thời điểm bắt đầu đóng trả góp: Tháng Khách hàng đi học khóa đầu tiên trong combo đăng ký. Trường hợp thời điểm đóng giá gốc của khóa đầu tiên trùng với tháng Khách hàng đi học khóa đầu tiên thì thời điểm bắt đầu đóng trả góp sẽ được lùi lại 01 (một) tháng;")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Kể từ thời điểm bắt đầu đóng trả góp, Khách hàng cần hoàn thành số tiền trả góp liên tục hàng tháng cho đến khi hoàn thành xong học phí;")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Trường hợp Khách hàng trả góp không đóng trả góp đúng hạn: các khóa trong combo sẽ được tính theo giá gốc (không áp dụng bất kỳ chương trình khuyến mại nào);")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("- Khách hàng chuyển khoản trực tiếp vào tài khoản của Trung tâm:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("+ Ngân hàng thương mại cổ phần Quân đội ")]),_c('span',{staticClass:"font-bold"},[_c('strong',[_vm._v("MBbank")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"font-bold"},[_c('strong',[_vm._v("+ Chủ tài khoản")])]),_c('span',[_vm._v(": Hồ Văn Hoàn")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"font-bold"},[_c('strong',[_vm._v("+ Số tài khoản")])]),_c('span',[_vm._v(": 4666999666999")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"font-bold"},[_c('strong',[_vm._v("+ Nội dung chuyển khoản")])]),_c('span',[_vm._v(": Số điện thoại  Họ và Tên Khách hàng")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-bold",staticStyle:{"text-align":"justify"}},[_c('span',[_c('strong',[_c('em',[_vm._v("Ví dụ: 0123456789 Nguyen Van A")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("Nếu sai Nội dung chuyển khoản, Khách hàng vui lòng liên hệ trực tiếp qua kênh IELTS Mentor Support Centre của IELTS Mentor để cung cấp thông tin. Nếu không, IELTS Mentor sẽ không nắm được thông tin, đồng nghĩa khoản thanh toán của Khách hàng sẽ không được ghi nhận.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify"}},[_c('span',[_vm._v("Đầu tiên, Khách hàng cần đóng số tiền tối thiểu bằng học phí gốc của khóa đầu tiên. Sau đó, hoàn thành học phí còn lại của combo trong vòng 60 ngày kể từ ngày đóng phí lần 1. Nếu quá 60 ngày kể từ ngày đóng phí lần 1, Khách hàng chưa hoàn thành 100% học phí, các khóa trong combo tính theo giá gốc (không áp dụng bất kỳ chương trình khuyến mại nào).")])])
}]

export { render, staticRenderFns }