export const typeFormatText = {
    'bo': 'Bố',
    'me': 'Mẹ',
    'khac': 'Khác'
}

export const tuitionPayment = {
    1: 'Hoàn thành toàn bộ',
    2: 'Trả góp ngân hàng',
    3: 'Trả góp trung tâm',
    4: 'Đóng theo giai đoạn',
    5: 'Trả góp Citime',
}

export const tuitionPaymentCss = {
    1: 'badge badge-primary',
    2: 'badge badge-success',
    3: 'badge badge-info',
    4: 'badge badge-danger',
    5: 'badge badge-red text-white'
}

export const tuitionPaymentSelect = [
    {
        id: 1,
        name: 'Hoàn thành toàn bộ',
    },
    {
        id: 2,
        name: 'Trả góp ngân hàng',
    },
    {
        id: 3,
        name: 'Trả góp trung tâm',
    },
    {
        id: 4,
        name: 'Đóng theo giai đoạn'
    },
    {
        id: 5,
        name: 'Trả góp Citime'
    }
];
//
export const status = {
    0: 'Chờ xác thực',
    1: 'Đã xác thực',
    2: 'Đã hủy',
    3: 'Đã đóng tiền'
}

export const status_select = [
    {
        id: 0,
        name: 'Chờ xác thực',
    },
    {
        id: 1,
        name: 'Đã xác thực',
    },
    {
        id: 2,
        name: 'Đã hủy',
    },
    {
        id: 3,
        name: 'Đã đóng tiền',
    }
];

export const status_define = {
    0: "Chờ xác thực",
    1: "Đã xác thực",
    2: "Đã hủy",
    3: "Đã đóng tiền",
};

export const status_define_css = {
    0: "badge badge-secondary",
    1: "badge badge-success",
    2: "badge badge-danger",
    3: "badge badge-primary",
};


export const price_select = [
    {
        id: 0,
        name: 'Chưa đóng tiền',
    },
    {
        id: 1,
        name: 'Dưới 1.200.000đ',
    },
    {
        id: 2,
        name: 'Từ 1.200.000đ trở lên',
    }
];

export const EDUCATIONS = [
    {
        id: 0,
        name: 'Trung học cơ sở (Cấp 2)',
    },
    {
        id: 1,
        name: 'Trung học phổ thông (Cấp 3)',
    },
    {
        id: 2,
        name: 'Trung cấp/Cao đẳng/Đại học',
    },
    {
        id: 3,
        name: 'Sau đại học',
    }
];

export const THCS = 0;
export const THPT = 1;
export const DAI_HOC = 2;
export const SAU_DAI_HOC = 3;





