<template>
  <div class="row">
    <!--  Thông tin  người xác thực   -->
    <div class="col-md-4" v-if="!contract.is_older">
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <p class="font-weight-bold">Thông tin người xác thực
              <el-tooltip class="item cursor-pointer" effect="dark"
                          content="Trường hợp khách hàng dưới 15 tuổi, người giám hộ là người xác thực phiếu đăng ký học tập"
                          placement="top">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </p>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p>Mối quan hệ:</p>
            <p>
            <span class="badge badge-primary">
              {{ contract.parent && contract.parent.type ? typeFormatText[contract.parent.type] : '' }}
              </span>
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>Họ tên người giám hộ:</p>
            <p><span class="badge badge-primary">{{
                contract.parent && contract.parent.name ? contract.parent.name : ''
              }}</span></p>
          </div>
          <div class="d-flex justify-content-between">
            <p>Số điện thoại người giám hộ:</p>
            <p><span class="badge badge-primary">{{
                contract.parent && contract.parent.phone ? contract.parent.phone : ''
              }}</span></p>
          </div>
          <div class="d-flex justify-content-between">
            <p>SĐT người giám hộ khác:</p>
            <p>
              <span class="badge badge-primary">{{
                  contract.parent && contract.parent.phone_other ? contract.parent.phone_other : '(Không có)'
                }}</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4" v-else>
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <p class="font-weight-bold">Thông tin người xác thực
              <el-tooltip class="item cursor-pointer" effect="dark"
                          content="Trường hợp khách hàng dưới 15 tuổi, người giám hộ là người xác thực phiếu đăng ký học tập"
                          placement="top">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </p>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p>Họ tên người giám hộ:</p>
            <p><span class="badge badge-primary">{{ contract.name }}</span></p>
          </div>
          <div class="d-flex justify-content-between">
            <p>Số điện thoại người giám hộ:</p>
            <p><span class="badge badge-primary">{{ contract.phone }}</span></p>
          </div>
          <div class="d-flex justify-content-between">
            <p>SĐT người giám hộ khác:</p>
            <p>
              <span class="badge badge-primary">{{ contract.phone_other ? contract.phone_other : '(Không có)' }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--End  -->
    <!--  Phương thức gưỉ   -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <p class="font-weight-bold">Phương thức gửi</p>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p><i class="el-icon-menu text-primary mr-2"></i>Qua tin nhắn SMS</p>
            <button class="btn btn-primary" @click="sendSms">
              <i v-if="is_loading_sms" class="el-icon-loading ml-2"></i>Gửi SMS
            </button>
          </div>
          <div class="d-flex justify-content-between mt-2">
            <p><i class="el-icon-menu text-primary mr-2"></i>Sao chép đường dẫn trực tiếp</p>
          </div>
          <div class="d-flex justify-content-between">
            <el-input disabled placeholder="Please input" :value="url_copy" id="url"></el-input>
            <button @click="copyUrl" class="btn btn-default ml-1"><i class="el-icon-document-copy v-size--small"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--End  -->
    <!--  Hướng dẫn khách hàng xác thực   -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <p class="font-weight-bold">Hướng dẫn xác thực phiếu đăng ký học tập</p>
          </div>
        </div>
        <div class="card-body">
          <p>1. Truy cập vào website thông qua tin nhắn SMS hoặc đường dẫn được Tư vấn viên gửi phía dưới.</p>
          <p>2. Đăng nhập bằng mã OTP gửi về số điện thoại.</p>
          <p>3. Đọc kỹ phiếu đăng ký học tập.</p>
          <p>4. Tích chọn xác nhận phiếu đăng ký học tập.</p>
        </div>
      </div>
    </div>
    <!--End  -->

  </div>
</template>

<script>
import {typeFormatText} from "@/core/config/contract/contractOnlineOption";
import {CONTRACT_ONLINE_SEND_LINK_SMS} from "@/core/services/store/contract/contractOnline.module";

export default {
  name: "CustomerConfirmForm",
  props: {
    contract: {
      type: Object,
      default: () => {
        return null;
      }
    },
    url_copy: {
      type: String,
      default: () => {
        return '';
      }
    },
    url_confirm_contract: {
      type: String,
      default: () => {
        return '';
      }
    },
  },
  data() {
    return {
      url_ecoach: `${process.env.VUE_APP_BASE_URL_ECOACH}`,
      url_ielts: `${process.env.VUE_APP_BASE_URL_IELTS}`,
      typeFormatText: typeFormatText,
      is_loading_sms: false
    }
  },
  methods: {
    copyUrl() {
      // Get the text field
      var copyText = document.getElementById("url");
      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);
      // Alert the copied text
      this.$message.success("Sao chép đường dẫn thành công");
    },
    sendSms() {
      this.is_loading_sms = true;
      this.$store.dispatch(CONTRACT_ONLINE_SEND_LINK_SMS, {
        url: this.url_confirm_contract,
        center_id: this.contract.center_id,
        phone: this.getPhone(this.contract.is_older),
      }).then((data) => {
        this.$message.success(data.message);
      }).catch((e) => {
        this.$message.error(e.message);
      }).finally(() => {
        this.is_loading_sms = false;
      });
    },
    getPhone(is_older) {
      let phone = this.contract.phone;
      if (!is_older) {
        phone = this.contract.parent.phone;
      }
      return phone;
    }
  }
}
</script>

<style scoped>

</style>