<template>
  <div class="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700 accordion-item">
    <!--thanh toán toàn bộ học phí-->
    <div v-if="contract && contract.tuition_payment_id == 1" id="thanh-toan-toan-bo-hoc-phi">
      <p style="text-align:justify"><span>Thời gian thanh toán: Hoàn thành 100% tổng giá trị hợp đồng trong tháng (Tại tháng có ngày đóng học phí lần 1).</span>
      </p>
      <p style="text-align:justify"><span>Lưu ý: Ngày đóng học phí lần 1 được tính khi tổng số tiền Khách hàng đã đóng lớn hơn hoặc bằng 1.200.000 VNĐ.</span>
      </p>
    </div>
    <!--thanh toán trả góp theo ngân hàng-->
    <div v-if="contract && contract.tuition_payment_id == 2" id="tra-gop-ngan-hang">
      <p class="font-bold"><span><strong>Thanh toán trả góp với Ngân Hàng qua Payoo</strong></span></p>

      <p style="text-align:justify"><span>- Thanh toán trả góp với Ngân hàng qua Payoo là hình thức thanh toán học phí bằng thẻ tín dụng của Ngân Hàng có liên kết với Payoo. </span></p>

      <p style="text-align:justify"><span>- Trong đó Payoo đóng vai trò là đơn vị trung gian về công nghệ kết nối Khách hàng có thẻ tín dụng Ngân hàng với Trung tâm và Khách hàng thực hiện trả góp với ngân hàng theo kỳ hạn tùy chọn và lãi suất tương ứng.</span></p>

      <p class="font-bold" style="text-align:justify"><span><strong>1. Đối tượng áp dụng</strong></span></p>

      <p class="font-bold" style="text-align:justify"><span><strong>- Khách hàng đã có thẻ tín dụng Ngân hàng</strong></span></p>

      <p style="text-align:justify"><span>+ Yêu cầu đối với thẻ tín dụng: Nằm trong danh sách 25 Ngân hàng liên kết với Payoo.</span></p>

      <p style="text-align:justify"><span>+ Danh sách 25 Ngân hàng liên kết Payoo gồm: Techcombank, ACB, BIDV, Vietcombank, Vietinbank, Sacombank, TPBank, VIB, MSB, MB, OCB, SCB, Seabank, Pvcombank, Citibank, Eximbank, FECredit, HDBank, HSBC, Kiên Long Bank, Lien Viet Post Bank, Nam A Bank, SHB, Shinhan Bank, Viet Capital Bank .</span><br/><br/>
        <span class="font-bold"><strong>- Khách hàng chưa mở thẻ tín dụng Ngân hàng</strong></span><br />
        <span>Trung tâm hỗ trợ mở thẻ tín dụng của Ngân hàng Sacombank.</span></p>

      <ul>
        <li class="ml-10" style="list-style-type:disc"><span><strong>Đối tượng mở thẻ</strong></span><br />
          <span>Áp dụng mở thẻ đối với Khách hàng hoặc Bố/Mẹ khách hàng đang làm việc/ sinh sống tại các tỉnh thành phố: Hà Nội, Hồ Chí Minh, Thanh Hóa, Hà Tĩnh,... </span></li>
        <li class="ml-10" style="list-style-type:disc"><span><strong> Hồ sơ mở thẻ</strong></span>
          <ul>
            <li style="list-style-type:circle"><span>Căn cước công dân gắn chip hoặc chứng minh thư & Sổ hộ khẩu photo;</span></li>
            <li style="list-style-type:circle"><span>Màn hình tin nhắn lương 03 tháng gần nhất;</span></li>
            <li style="list-style-type:circle"><span> Form đăng ký trả góp với Ngân hàng Sacombank.</span></li>
            <li style="list-style-type:circle"><span>Đối với Khách hàng có sổ hộ khẩu Tình cần có 1 trong 4 giấy tờ sau:</span></li>
          </ul>
        </li>
      </ul>

      <p style="margin-left: 80px; text-align:justify"><span>+ Tạm trú tạm vắng</span></p>

      <p style="margin-left: 80px; text-align:justify"><span>+ Hoá đơn điện</span></p>

      <p style="margin-left: 80px; text-align:justify"><span>+ Mã PE</span></p>

      <p style="margin-left: 80px; text-align:justify"><span>+ Hợp đồng thuê nhà;</span></p>

      <p class="font-bold" style="text-align:justify"><span><strong>2. Chính sách thanh toán trả góp</strong></span></p>

      <p style="text-align:justify"><span>- Thanh toán trả góp chỉ áp dụng cho khoản tiền từ 2.000.000 VNĐ (hai triệu đồng) trở lên;</span></p>

      <p style="text-align:justify"><span>- Sau khi đăng ký khóa học thành công bằng hình  thức thanh toán trả góp với Ngân Hàng qua Payoo Khách hàng có thể sử dụng khóa học như bình thường (như thanh toán các hình thức khác);</span></p>

      <p style="text-align:justify"><span>- Thời hạn đăng ký trả góp: 6, 9 hoặc 12 tháng.</span></p>

      <p class="font-bold" style="text-align:justify"><span><strong>3. Điều kiện học phí </strong></span></p>

      <p style="text-align:justify"><span>- Khách hàng cần đóng số tiền tối thiểu bằng 20% tổng giá trị hợp đồng trong lần thanh toán đầu tiên và hoàn thành đầy đủ học phí trong tháng (Tại tháng có ngày đóng học phí lần 1).</span></p>

      <p style="text-align:justify"><span>Lưu ý: Ngày đóng học phí lần 1 được tính khi tổng số tiền Khách hàng đã đóng lớn hơn hoặc bằng 1.200.000 VNĐ.</span></p>

      <p class="font-bold" style="text-align:justify"><span><strong>4. Mức phí</strong></span></p>

      <p style="text-align:justify"><span>- Phí và lãi suất chuyển đổi trả góp là 9% giá trị quẹt thẻ trong đó:</span><br/><br/>
        <span>+ Trung tâm hỗ trợ phí lãi suất trả góp là 6,5% giá trị quẹt thẻ;</span></p>

      <p style="text-align:justify"><span>+ Khách hàng chỉ cần thanh toán phần phí lãi suất trả góp còn lại, tương đương với 2.5% giá trị quẹt thẻ.</span></p>

      <p class="font-bold" style="text-align:justify"><span><strong>5. Các bước đăng ký</strong></span></p>

      <p style="text-align:justify"><span class="font-bold"><strong><u>Cách 1</u></strong></span><span>: Đến trực tiếp các Trung tâm để được chuyên viên tư vấn tại văn phòng hướng dẫn;</span></p>

      <p style="text-align:justify"><span class="font-bold"><strong><u>Cách 2</u></strong></span><span>: Nếu có nhu cầu, hãy báo với tư vấn viên. Đội ngũ chuyên viên chăm sóc Khách hàng sẽ hướng dẫn và giải đáp các thắc mắc của Khách hàng.</span></p>
    </div>
    <div v-if="contract && contract.tuition_payment_id == 3" id="tra-gop-trung tam">
      <p style="text-align:justify"><span>Thanh toán trả góp qua Trung tâm (sau đây gọi là “thanh toán trả góp”) là hình thức thanh toán học phí bằng Điều khoản dịch vụ với Trung tâm ECoach, sau đó thực hiện trả góp với Trung tâm theo kỳ hạn và lãi suất tương ứng.</span>
      </p>

      <p class="font-bold" style="text-align:justify"><span><strong>1. Đối tượng áp dụng:</strong></span></p>

      <p style="text-align:justify">
        <span>Toàn bộ Khách hàng đang sinh sống/học tập và làm việc trên lãnh thổ Việt Nam:</span></p>

      <p><span>- Từ 22 - 60 tuổi: bản thân Khách hàng;</span></p>
      <p><span>- Ủy quyền bố/ mẹ nếu là học sinh hoặc sinh viên.</span></p>

      <p class="font-bold" style="text-align:justify"><span><strong>2. Chính sách thanh toán trả góp</strong></span></p>

      <p><span>- Thanh toán trả góp chỉ áp dụng cho khoản tiền từ 3.000.000 VNĐ (ba triệu đồng) trở lên;</span></p>

      <p><span>- Sau khi đăng ký khóa học thành công bằng hình thức thanh toán trả góp, Khách hàng có thể sử dụng khóa học như bình thường (như thanh toán Khách hàng các hình thức khác);</span>
      </p>
      <p><span>- Việc trả góp sau đó là nghĩa vụ của Khách hàng với bên Trung tâm, Khách hàng cần đảm bảo thanh toán đúng hạn hàng tháng để đảm bảo quá trình học tập và quyền lợi của Khách hàng. Trường hợp không đúng hạn, Trung tâm có quyền tạm dừng cung cấp dịch vụ với Khách hàng ở khóa học tiếp theo.</span>
      </p>

      <p class="font-bold" style="text-align:justify"><span><strong>3. Hồ sơ đăng ký trả góp Trung tâm:</strong></span>
      </p>

      <p><span>- Căn cước công dân gắn chip hoặc Chứng minh nhân dân/Căn cước công dân không gắn chip kèm sổ hộ khẩu còn giá trị;</span>
      </p>
      <p><span>- Form đăng ký trả góp với Trung tâm.</span></p>

      <p style="text-align:justify"><span class="font-bold"><strong>4. Điều kiện học phí</strong></span><span>: Khách hàng cần đóng số tiền tối thiểu bằng giá gốc của khóa đầu tiền trong combo đăng ký để hoàn thành hồ sơ trả góp.</span>
      </p>

      <p class="font-bold"><span><strong>5. Mức phí</strong></span></p>

      <p><span>- Số tiền trả góp hàng tháng: 2.000.000 VNĐ/ tháng. </span></p>
      <p>
        <span>- Phí và lãi suất trả góp: 2% giá trị đăng ký trả góp. ECoach hỗ trợ 100% chi phí lãi suất cho Khách hàng</span>
      </p>

      <p class="font-bold"><span><strong>6. Các bước đăng ký</strong></span></p>

      <p style="text-align:justify"><span class="font-bold"><strong><u>Cách 1</u></strong></span><span>: Đến trực tiếp các văn phòng thuộc hệ thống ECoach để được chuyên viên tư vấn tại văn phòng hướng dẫn;</span>
      </p>

      <p style="text-align:justify"><span class="font-bold"><strong><u>Cách 2</u></strong></span><span>: Nếu có nhu cầu, hãy báo với tư vấn viên của ECoach. Đội ngũ chuyên viên chăm sóc Khách hàng của ECoach sẽ hướng dẫn và giải đáp các thắc mắc của Khách hàng.</span>
      </p>

      <p class="font-bold"><span><strong>7. Thời hạn thanh toán Trung tâm</strong></span></p>

      <p><span>- Thời điểm bắt đầu đóng trả góp: Tháng Khách hàng đi học khóa đầu tiên trong combo đăng ký. Trường hợp thời điểm đóng giá gốc của khóa đầu tiên trùng với tháng Khách hàng đi học khóa đầu tiên thì thời điểm bắt đầu đóng trả góp sẽ được lùi lại 01 (một) tháng;</span>
      </p>
      <p><span>- Kể từ thời điểm bắt đầu đóng trả góp, Khách hàng cần hoàn thành số tiền trả góp liên tục hàng tháng cho đến khi hoàn thành xong học phí;</span>
      </p>
      <p><span>- Trường hợp Khách hàng trả góp không đóng trả góp đúng hạn: các khóa trong combo sẽ được tính theo giá gốc (không áp dụng bất kỳ chương trình khuyến mại nào);</span>
      </p>
      <p><span>- Khách hàng chuyển khoản trực tiếp vào tài khoản của Trung tâm:</span></p>

      <p><span>+ Ngân hàng thương mại cổ phần Quân đội </span><span class="font-bold"><strong>MBbank</strong></span></p>
      <p><span class="font-bold"><strong>+ Chủ tài khoản</strong></span><span>: Hồ Văn Hoàn</span></p>
      <p><span class="font-bold"><strong>+ Số tài khoản</strong></span><span>: 4666999666999</span></p>
      <p><span class="font-bold"><strong>+ Nội dung chuyển khoản</strong></span><span>: Số điện thoại  Họ và Tên Khách hàng</span>
      </p>

      <p class="font-bold" style="text-align:justify">
        <span><strong><em>Ví dụ: 0123456789 Nguyen Van A</em></strong></span></p>

      <p style="text-align:justify"><span>Nếu sai Nội dung chuyển khoản, Khách hàng vui lòng liên hệ trực tiếp qua Zalo CSKH ECoach (được tham gia trong quá trình học tập) để cung cấp thông tin. Nếu không, ECoach sẽ không nắm được thông tin, đồng nghĩa khoản thanh toán của Khách hàng sẽ không được ghi nhận.</span>
      </p>
    </div>
    <!--                                                Thanh toán chia theo giai đoạn-->
    <div v-if="contract && contract.tuition_payment_id == 4" id="thanh-toan-chia-theo-giai-doan">
      <p style="text-align:justify"><span>Đầu tiên, Khách hàng cần đóng số tiền tối thiểu bằng học phí gốc của khóa đầu tiên. Sau đó, hoàn thành học phí còn lại của combo trong vòng 60 ngày kể từ ngày đóng phí lần 1. Nếu quá 60 ngày kể từ ngày đóng phí lần 1, Khách hàng chưa hoàn thành 100% học phí, các khóa trong combo tính theo giá gốc (không áp dụng bất kỳ chương trình khuyến mại nào).</span>
      </p>
    </div>
<!--    <div v-else>-->
<!--      <p>Chưa có hình thức thanh toán</p>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "ecoach_collapse_hinh_thuc_hoan_thanh_hoc_phi",
  props: {
    contract: {
      type: Object,
      default: () => {
        return false;
      }
    }
  }
}
</script>

<style scoped>
li::marker {
  font-size: 15px;
}
</style>