<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body border-primary">
          <p class="font-weight-bold h4">Thông tin khách hàng</p>
          <div class="d-flex justify-content-between">
            <p class="font-weight-bold">Họ và tên:</p>
            <p class="font-weight-bold">{{ contract.name }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="font-weight-bold">Số điện thoại:</p>
            <p class="font-weight-bold">{{ contract.phone }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="font-weight-bold">SĐT khác:</p>
            <p class="font-weight-bold">{{ contract.phone_other }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="font-weight-bold">Email:</p>
            <p class="font-weight-bold">{{ contract.email }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="font-weight-bold">Ngày sinh:</p>
            <p class="font-weight-bold">{{ contract.birthday | formatDate }}</p>
          </div>
          <div v-if="!contract.is_older">
            <p class="font-weight-bold h4 mt-4">Thông tin người giám hộ</p>
            <div class="d-flex justify-content-between">
              <p class="font-weight-bold">Mối quan hệ:</p>
              <p class="font-weight-bold">{{ contract.parent.type ? typeFormatText[contract.parent.type] : '' }}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="font-weight-bold">Họ và tên người giám hộ:</p>
              <p class="font-weight-bold">{{ contract.parent.name }}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="font-weight-bold">Số điện thoại người giám hộ:</p>
              <p class="font-weight-bold">{{ contract.parent.phone }}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="font-weight-bold">Số điện thoại người giám hộ khác:</p>
              <p class="font-weight-bold">{{ contract.parent.phone_other }}</p>
            </div>
          </div>
          <p class="font-weight-bold h4 mt-4">Thông tin đơn hàng</p>
          <p class="font-weight-bold h5">Danh sách khóa học</p>
          <div class="d-flex justify-content-between ml-2" v-for="(item, index) in contract.courses" :key="index">
            <p class="font-weight-bold"><i class="el-icon-menu text-primary mr-2"></i>{{ item.name }}</p>
            <p class="font-weight-bold">{{ item.fee | formatVND }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="font-weight-bold">Tổng giá gốc:</p>
            <p class="font-weight-bold">{{ contract.total | formatVND }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="font-weight-bold">Tổng khuyến mại:</p>
            <p class="font-weight-bold text-success">{{ contract.total_promotions | formatVND }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="font-weight-bold">Tổng thanh toán:</p>
            <p class="font-weight-bold text-danger">{{ (contract.total - contract.total_promotions) | formatVND }}</p>
          </div>
          <p class="font-weight-bold h4 mt-4">Hình thức hoàn thành học phí</p>
          <hr>
          <div class="d-flex justify-content-between">
            <span class="badge badge-primary">
               {{
                contract.tuition_payment_id ? tuitionPayment[contract.tuition_payment_id] : 'Chưa chọn hình thức thanh toán'
              }}
            </span>
          </div>
          <div class="mt-3" v-if="contract && contract.description">
            <p class="font-weight-bold h4">Quà tặng</p>
            <el-input
				disabled
                v-if="contract.description"
                v-model="contract.description"
                :rows="5"
                type="textarea"
                placeholder="Quà tặng"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card">
        <div class="card-body">
          <p class="font-weight-bold h4 text-center">Điều khoản chính sách</p>
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item
                v-for="(item) in (contract.policy ? contract.policy : policy)"
                :title="item.name"
                :name="item.policy_catalog ? item.policy_catalog.id : (item.id ?? '')"
            >
              <div class="policy-content table-ck ql-editor" v-html="item.content"></div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ecoach_collapse_hinh_thuc_hoan_thanh_hoc_phi
  from "@/views/pages/customer/components/EcoachPolicy/ecoach_collapse_hinh_thuc_hoan_thanh_hoc_phi";
import ecoach_collapse_chinh_sach_bao_mat
  from "@/views/pages/customer/components/EcoachPolicy/ecoach_collapse_chinh_sach_bao_mat";
import ecoach_collapse_dieu_khoan_dang_ky_hoc
  from "@/views/pages/customer/components/EcoachPolicy/ecoach_collapse_dieu_khoan_dang_ky_hoc";
import ecoach_collapse_hinh_thuc_thanh_toan
  from "@/views/pages/customer/components/EcoachPolicy/ecoach_collapse_hinh_thuc_thanh_toan";
import ecoach_collapse_chinh_sach_hoc_tap
  from "@/views/pages/customer/components/EcoachPolicy/ecoach_collapse_chinh_sach_hoc_tap";
import {tuitionPayment, typeFormatText} from "@/core/config/contract/contractOnlineOption";
import {ECOACH} from "@/core/config/center/centerOption";
import Ieltsmentor_collapse_chinh_sach_bao_mat
  from "@/views/pages/customer/components/IMPolicy/ieltsmentor_collapse_chinh_sach_bao_mat";
import Ieltsmentor_collapse_dieu_khoan_dang_ky_hoc
  from "@/views/pages/customer/components/IMPolicy/ieltsmentor_collapse_dieu_khoan_dang_ky_hoc";
import Ieltsmentor_collapse_hinh_thuc_thanh_toan
  from "@/views/pages/customer/components/IMPolicy/ieltsmentor_collapse_hinh_thuc_thanh_toan";
import Ieltsmentor_collapse_hinh_thuc_hoan_thanh_hoc_phi
  from "@/views/pages/customer/components/IMPolicy/ieltsmentor_collapse_hinh_thuc_hoan_thanh_hoc_phi";
import Ieltsmentor_collapse_chinh_sach_hoc_tap
  from "@/views/pages/customer/components/IMPolicy/ieltsmentor_collapse_chinh_sach_hoc_tap";

export default {
  name: "CompletedStudyForm",
  components: {
    Ieltsmentor_collapse_chinh_sach_hoc_tap,
    Ieltsmentor_collapse_hinh_thuc_hoan_thanh_hoc_phi,
    Ieltsmentor_collapse_hinh_thuc_thanh_toan,
    Ieltsmentor_collapse_dieu_khoan_dang_ky_hoc,
    Ieltsmentor_collapse_chinh_sach_bao_mat,
    ecoach_collapse_chinh_sach_hoc_tap,
    ecoach_collapse_hinh_thuc_thanh_toan,
    ecoach_collapse_dieu_khoan_dang_ky_hoc,
    ecoach_collapse_chinh_sach_bao_mat,
    ecoach_collapse_hinh_thuc_hoan_thanh_hoc_phi
  },
  props: {
    contract: {
      type: Object,
      default: () => {
        return null;
      }
    },
    policy: {
      default: null
    }
  },
  data() {
    return {
      ECOACH: ECOACH,
      tuitionPayment: tuitionPayment,
      typeFormatText: typeFormatText,
      is_ecoach: false,
      activeName: 1,
    }
  },
  mounted() {
    if (this.contract.center_id === ECOACH) {
      this.is_ecoach = true;
    } else {
      this.is_ecoach = false;
    }
  }
}
</script>

<style scoped>
</style>