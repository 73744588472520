export const CUSTOMER_SELECT_DECISION = [
    {
        id: 1,
        name: "Đang phân vân",
    },
    {
        id: 2,
        name: "Không đăng ký",
    },
    {
        id: 3,
        name: "Đăng ký",
    },
];

export const PRODUCT_CUSTOMER_SELECT = [
    {
        id: 1,
        name: "Sản phẩm thuộc Edutalk",
    },
    {
        id: 2,
        name: "Sản phẩm thuộc nhãn Đối tác",
    }
];

export const HINH_THUC_TU_VAN = {
    0: 'Tư vấn không qua Edutalk',
    1: 'Tư vấn qua Edutalk'
}

export const PHAN_VAN = 1; // Đang phân vân
export const KHONG_DANG_KY = 2; // Không đăng ký
export const DANG_KY = 3; // Đăng ký

export const SAN_PHAM_EDUTALK = 1;
export const SAN_PHAM_NHAN_DOI_TAC = 2;

// levels
export const LEVEL_3A = '3A';

export const LEVEL_CONSULTED = [
    '3A', '3E', '4A', '4B', '4D', '6A', '7', '8'
];







