<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="form" @submit.prevent="handleSubmit(summit)" autocomplete="off">
      <div class="row">
        <div class="col-md-6">
          <p class="h4 font-weight-bold">Thông tin khách hàng</p>
          <div class="form-group">
            <label class="form-label col-form-label">Họ và tên <span class="text-danger">*</span></label>
            <ValidationProvider vid="name" name="Họ và tên" rules="required" v-slot="{ errors }">
              <el-input :disabled="is_show" filterable class="w-100" placeholder="Họ và tên"
                        v-model="contract.name"></el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="form-label col-form-label">Số điện thoại<span class="text-danger">*</span></label>
            <ValidationProvider vid="phone" name="Số điện thoại"
                                :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }" v-slot="{ errors }">
              <el-input disabled @input="checkUniquePhone" filterable class="w-100" placeholder="Số điện thoại"
                        v-model="contract.phone"></el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="form-label col-form-label">Số điện thoại khác<span class="text-danger"></span></label>
            <ValidationProvider vid="phone_other"
                                name="Nhập số điện thoại khác"
                                :rules="{ required: false,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                v-slot="{ errors }">
              <el-input v-model="contract.phone_other"
                        :disabled="is_show"
                        filterable class="w-100"
                        placeholder="Nhập số điện thoại khác"></el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="form-label col-form-label">Email<span class="text-danger">*</span></label>
            <ValidationProvider vid="email" name="Nhập Email" rules="required" v-slot="{ errors }">
              <el-input :disabled="is_show" v-model="contract.email" filterable class="w-100"
                        placeholder="Nhập Email"></el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="form-label col-form-label">Ngày sinh<span class="text-danger">*</span></label>
            <ValidationProvider vid="birthday" name="Ngày sinh" rules="required" v-slot="{ errors }">
              <el-date-picker
                  :disabled="is_show"
                  @change="handleBirthday"
                  v-model="contract.birthday"
                  type="date"
                  format="dd-MM-yyyy"
                  placeholder="Nhập ngày sinh">
              </el-date-picker>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <p class="h4 font-weight-bold mb-4">Tạo đơn hàng</p>
          <div class="form-group">
            <label class="form-label col-form-label">Trung tâm đăng ký học<span class="text-danger">*</span></label>
            <ValidationProvider vid="center_id" name="Trung tâm" rules="required" v-slot="{ errors }">
              <el-select filterable
                         :disabled="is_show"
                         v-model="contract.center_id"
                         placeholder="Chọn trung tâm" clearable
                         @input="handleCenter">
                <el-option
                    v-for="item in centers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="form-label col-form-label">Chi nhánh học tập<span class="text-danger">*</span></label>
            <ValidationProvider vid="branch_id" name="Chi nhánh" rules="required" v-slot="{ errors }">
              <el-select filterable
                         :disabled="is_show"
                         v-model="contract.branch_id"
                         placeholder="Chọn chi nhánh"
                         clearable
                         @input="handleBranch">
                <el-option
                    v-for="item in branches"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <p class="h4 font-weight-bold mb-4">Danh sách khóa học</p>
          <div v-for="(item, index) in contract.courses" :key="index" class="courses">
            <div class="form-group">
              <label class="form-label col-form-label">Khóa học {{ index + 1 }}<span
                  class="text-danger">*</span></label>
              <div class="row">
                <div class="col-md-10">
                  <ValidationProvider :vid="'courses.'+index+'.id'" name="Khóa học" rules="required"
                                      v-slot="{ errors }">
                    <el-select filterable
                               :disabled="is_show"
                               v-model="item.id"
                               placeholder="Chọn khóa học"
                               clearable
                               @input="handleCourse(item)">
                      <el-option
                          v-for="item in courses"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                          :disabled="item.disabled"
                      >
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div v-if="index != 0" class="mt-2 col-md-2 cursor-pointer" @click="removeCourse(index)">
                  <i class="el-icon-delete  font-weight-bold text-danger fa-lg"></i>
                </div>
              </div>
            </div>
          </div>
          <el-button class="mt-4" @click="addNewCourse()" size="mini" type="primary" icon="el-icon-plus">Thêm khóa
          </el-button>
          <div class="form-group">
            <label class="form-label col-form-label">Hình thức hoàn thành học phí<span
                class="text-danger">*</span></label>
            <ValidationProvider vid="tuition_payment_id" name="Hình thức hoàn thành học phí" rules="required" v-slot="{ errors }">
              <el-select filterable
                         :disabled="is_show"
                         v-model="contract.tuition_payment_id"
                         placeholder="Chọn phương thức thanh toán"
                         @input="handleTuitionPayment"
                         clearable>
                <el-option
                    v-for="item in tuitions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div v-if="contract.tuition_payment_id == 2">
            <p>Với hình thức trả góp ngân hàng, vui lòng kiểm tra thông tin tín
              dụng của khách hàng/người giám hộ.</p>
            <p class="font-weight-light" style="font-size: 12px">Lưu ý: Trường hợp không đủ điều kiện trả góp ngân hàng,
              tư vấn viên tư vấn lại
              hình thức hoàn thành học phí
              cho khách hàng và chỉnh sửa lại thông tin liên quan trong phiếu đăng ký học tập.</p>
            <a href="javascript:0;" class="text-primary" @click="checkCicDialogVisible = true">Xem hướng dẫn
              check CIC</a>
          </div>
          <div>
            <label class="form-label col-form-label">Voucher & Quà tặng</label>
          </div>
          <div class="col-md-12 d-flex ">
<!--            <el-badge :value="numberGift" class="item" type="success">-->
<!--              <el-button @click="openListGift()" size="small">Chọn voucher & quà tặng</el-button>-->
<!--            </el-badge>-->
            <el-badge :value="promotionTotal" class="item " type="success">
              <el-button @click="viewVoucher()" size="small"> Chọn voucher & quà tặng</el-button>
            </el-badge>
          </div>
          <div v-for="(item, index) in list_promotions_selected" :key="'a'+index">
            <div class="promotions row mt-4" v-if="list_promotions_selected.length > 0">
              <div class="col-md-5 font-weight-bold">
                <span v-if="item.type">Quy đổi quà tặng - {{ item.name }}</span>
                <span v-if="item.type == 0">{{ item.name }}</span>
                <span v-if="item.type == 0">(Voucher)</span>
              </div>
              <div class="col-md-6 font-weight-bold">
              <span v-if="item.unit === 0">
                <span class="badge badge-success"> {{ item.value }}%</span>
              </span>
								<span v-else>
                <span class="badge badge-success">{{ item.value | formatVND }}</span>
              </span>
							</div>
							<div class="col-md-1">
<!--								<i class="el-icon-delete mt-2 font-weight-bold text-danger fa-lg cursor-pointer"-->
<!--									 @click="unPickPromotions(item)"></i>-->
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="parent" v-if="!contract.is_older">
						<p class="h4 font-weight-bold">Thông tin người giám hộ
							<el-tooltip class="item" effect="dark"
													content="Trường hợp khách hàng dưới 15 tuổi, người giám hộ là người xác thực phiếu đăng ký học tập"
													placement="top">
								<i class="el-icon-info"></i>
							</el-tooltip>
						</p>
						<div class="form-group">
							<label class="form-label col-form-label d-block">Mối quan hệ<span class="text-danger"></span>
							</label>
							<ValidationProvider vid="type" name="Mối quan hệ" rules="required" v-slot="{ errors }">
								<el-radio-group v-model="contract.parent.type" size="small">
									<el-radio-button label="bo">Bố</el-radio-button>
									<el-radio-button label="me">Mẹ</el-radio-button>
									<el-radio-button label="khac">Khác</el-radio-button>
								</el-radio-group>
								<div class="fv-plugins-message-container">
									<div data-field="name" data-validator="notEmpty" class="fv-help-block">
										{{ errors[0] }}
									</div>
								</div>
							</ValidationProvider>
						</div>
						<div class="form-group" v-if="contract.parent.type === 'khac'">
							<label class="form-label col-form-label">Nhập mối quan hệ với khách hàng<span
								class="text-danger">*</span></label>
							<ValidationProvider vid="other_value" name="Mối quan hệ khác" rules="required" v-slot="{ errors }">
								<el-input :disabled="is_show"
													filterable
													class="w-100"
													placeholder="Nhập mối quan hệ với khách hàng"
													v-model="contract.parent.other_value">
								</el-input>
								<div class="fv-plugins-message-container">
									<div data-field="name" data-validator="notEmpty" class="fv-help-block">
										{{ errors[0] }}
									</div>
								</div>
							</ValidationProvider>
						</div>
						<div class="form-group">
							<label class="form-label col-form-label">Họ tên người giám hộ<span
								class="text-danger">*</span></label>
							<ValidationProvider vid="parent.name" name="Họ và tên người giám hộ" rules="required" v-slot="{ errors }">
								<el-input filterable
													:disabled="is_show"
													class="w-100"
													placeholder="Họ tên người giám hộ"
													v-model="contract.parent.name"></el-input>
								<div class="fv-plugins-message-container">
									<div data-field="name" data-validator="notEmpty" class="fv-help-block">
										{{ errors[0] }}
									</div>
								</div>
							</ValidationProvider>
						</div>
						<div class="form-group">
							<label class="form-label col-form-label">Số điện thoại người giám hộ<span
								class="text-danger">*</span></label>
							<ValidationProvider vid="parent.phone" name="Số điện thoại người giám hộ"
																	:rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
																	v-slot="{ errors }">
								<el-input @input="checkUniquePhone"
													:disabled="is_show"
													filterable class="w-100"
													placeholder="Số điện thoại người giám hộ"
													v-model="contract.parent.phone"></el-input>
								<div class="fv-plugins-message-container">
									<div data-field="name" data-validator="notEmpty" class="fv-help-block">
										{{ errors[0] }}
									</div>
								</div>
							</ValidationProvider>
						</div>
						<div class="form-group">
							<label class="form-label col-form-label">Số điện thoại người giám hộ khác(Nếu có)<span
								class="text-danger"></span></label>
							<ValidationProvider vid="name" name="Số điện thoại người giám hộ khác(nếu có)"
																	:rules="{ required: false,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
																	v-slot="{ errors }">
								<el-input filterable class="w-100"
													:disabled="is_show"
													placeholder="Số điện thoại người giám hộ"
													v-model="contract.parent.phone_other"></el-input>
								<div class="fv-plugins-message-container">
									<div data-field="name" data-validator="notEmpty" class="fv-help-block">
										{{ errors[0] }}
									</div>
								</div>
							</ValidationProvider>
						</div>
					</div>
					<p class="h4 font-weight-bold">Thông tin đơn hàng</p>
					<!-- Khóa học-->
					<p class="h5 font-weight-bold">Danh sách khóa học</p>
					<div v-if="contract.courses.length">
						<hr>
						<div class="d-flex justify-content-between mb-2" v-for="(item, index) in contract.student_register_courses"
								 :key=" 'b'+index">
            <span>
              <i class="el-icon-menu mr-4"></i>
              <span class="font-weight-bold">{{ item.course.name }}</span>
            </span>
              <span class="font-weight-bold">{{ item.fee | formatVND }}</span>
            </div>
            <!--End-->
          </div>
          <div v-else>
            <p class="font-weight-bold text-danger">Không có khóa học</p>
          </div>
          <hr>
          <div class="d-flex justify-content-between">
            <p class="h5">Tổng giá gốc</p>
            <p class="h5">{{ contract.total | formatVND }}</p>
          </div>
          <div class="d-flex justify-content-between ">
            <p class="h5">Tổng khuyến mại</p>
            <p class="text-success h5">{{ contract.total_promotions | formatVND }}</p>
          </div>
          <div class="d-flex justify-content-between ">
            <p class="font-weight-bold h4">Tổng thanh toán</p>
            <p class="text-danger h4">{{ total_need | formatVND }}</p>
          </div>
          <hr>
          <div class="gift" v-if="gift_select?.description">
            <p class="font-weight-bold h4">Quà tặng</p>
            <el-input
                :value="gift_select?.description"
                autosize
                type="textarea"
                placeholder="Quà tặng"
                style="white-space: pre;"
                disabled
            />

          </div>
        </div>
      </div>
    </form>
    <el-dialog
        id="gift"
        title="Chọn quà tặng"
        :visible.sync="giftDialogVisible"
        center
        style="right: 12px !important;"
        append-to-body

		>
			<div class="list-code-container">
				<el-input class="container mb-3" placeholder="Nhập tên quà tặng" @input="searchGift()"
									v-model="dataSearchGift"/>
				<div v-if="listGift.length > 0">
					<div

						v-for="(item, index) in listGift"
						:key="'c'+index"
						:v-if="item.id"

					>
						<div class="border border-info mb-3" style="background-image: linear-gradient(to bottom, #DFEAFF,#FFFFFF)">
							<GiftDialog
								:item="item"
								:listPromotions="list_promotions_selected"
								:giftSelect="gift_select"
								@pickPrice="pickPromotions(item)"
								@pickGift="pickGift(item)"
								@showDetailPromotion="showDetailPromotion(item)"
								@cancelGift="cancelGift(item)"
							></GiftDialog>
						</div>
					</div>
				</div>
				<div v-else class="no-promotion mt-2">
					<p>Không có quà tặng</p>
				</div>
			</div>
		</el-dialog>

		<!-- Modal show combo   -->
		<el-dialog
			:title="titleDetail"
			:visible.sync="detailPromotionDialogVisible"
			width="40%"
			center
			style="right: 12px !important"
			append-to-body
		>
		<div class="row">
      <div class="col-xl-6 col-md-12">
        <h1 style="font-size: 14px">Tên:</h1>
        <el-input
            placeholder="Please input"
            :value="promotionDetail.nameCode"
            >
        </el-input>
        <h1 style="font-size: 14px" class="mt-4">Ngày bắt đầu:</h1>
        <el-input
            placeholder="Please input"
            :value="promotionDetail.startDate"
            >
        </el-input>
      </div>
      <div class="col-xl-6 col-md-12">
        <h1 style="font-size: 14px">Giá trị:</h1>
        <el-input
            placeholder="Please input"
            :value="`${promotionDetail.value}${promotionDetail.unit == MONEY?'VND':'%'}`"
            >
        </el-input>
        <h1 style="font-size: 14px" class="mt-4">Ngày kết thúc:</h1>
        <el-input
            placeholder="Please input"
            :value="promotionDetail.endDate"
            :disabled="true">
        </el-input>
      </div>
      <div class="col-12">
        <h1 style="font-size: 14px" class="mt-4">Mô tả voucher:</h1>
        <el-input
            type="textarea"
            placeholder="Please input"
            :value="promotionDetail.description"
            :disabled="true">
        </el-input>
      </div>
    </div>
		</el-dialog>
		<!-- End   -->
		<!-- Modal show voucher   -->
		<el-dialog
			id="voucher"
			title="Danh sách voucher"
			:visible.sync="voucherDialogVisible"
			center
			append-to-body
		>
			<hr style="margin-top: -26px;">
			<div class="list-code-container" >
				<div class="row search-voucher" style="margin-bottom: 30px">
					<div class="col-md-12">
						<el-input v-model="searchVoucher" @input="searchVoucherByName()"
											placeholder="Nhập tên voucher"></el-input>
					</div>
				</div>
        <div v-if="loadingVoucherGift">
          <i class="el-icon-loading"></i>
          loading...
        </div>
        <div v-else style="overflow: auto;height: 700px">
          <div v-if="allCode[FLASH_SALE]?.length">
            <h1 style="font-size: 14px;font-weight: bold;text-align: left">Chương trình Flash sale</h1>
            <VoucherDialog :arrayVoucher="arrayVoucher" :listCode="searchVoucher?listCodeSearch[FLASH_SALE]:allCode[FLASH_SALE]" @openDetail="showDetailPromotion" :colorCode="'#FF8C22'" :allCode='allCode' :type="FLASH_SALE" @pickPromotions="morePickPromotions(FLASH_SALE)"></VoucherDialog>
          </div>
          <div class="mt-4" v-if="allCode[PERIODIC_PROMOTIONS]?.length">
            <h1 style="font-size: 14px;font-weight: bold;margin-bottom: 0px;text-align: left">Chương trình định kỳ</h1>
            <VoucherDialog :arrayVoucher="arrayVoucher" :listCode="searchVoucher?listCodeSearch[PERIODIC_PROMOTIONS]:allCode[PERIODIC_PROMOTIONS]" @openDetail="showDetailPromotion" :colorCode="'#1BC5BD'" :allCode='allCode' :type="PERIODIC_PROMOTIONS" @pickPromotions="morePickPromotions(PERIODIC_PROMOTIONS)"></VoucherDialog>
          </div>
          <div class="mt-4" v-if="allCode[SCHOOL_LINK]?.length">
            <h1 style="font-size: 14px;font-weight: bold;margin-bottom: 0px;text-align: left">Chương trình liên kết trường</h1>
            <VoucherDialog :arrayVoucher="arrayVoucher" :listCode="searchVoucher?listCodeSearch[SCHOOL_LINK]:allCode[SCHOOL_LINK]" @openDetail="showDetailPromotion" :colorCode="'#3343D0  '" :allCode='allCode' :type="SCHOOL_LINK" @pickPromotions="morePickPromotions(SCHOOL_LINK)"></VoucherDialog>
          </div>
          <div class="mt-4" v-if="allCode[OTHER_SCHOOL]?.length">
            <h1 style="font-size: 14px;font-weight: bold;margin-bottom: 0px;text-align: left">Chương trình khác</h1>
            <VoucherDialog :arrayVoucher="arrayVoucher" :listCode="searchVoucher?listCodeSearch[OTHER_SCHOOL]:allCode[OTHER_SCHOOL]" @openDetail="showDetailPromotion" :colorCode="'#D40000'" :allCode='allCode' :type="OTHER_SCHOOL" @pickPromotions="morePickPromotions(OTHER_SCHOOL)"></VoucherDialog>
          </div>
          <div class="mt-4" v-if="allCode[DIGITAL]?.length && contract.branch === digitalBranch">
            <h1 style="font-size: 14px;font-weight: bold;margin-bottom: 0px;text-align: left">Chương trình Digital</h1>
            <VoucherDialog :arrayVoucher="arrayVoucher" :listCode="searchVoucher?listCodeSearch[DIGITAL]:allCode[DIGITAL]" @openDetail="showDetailPromotion" :colorCode="'#0094ff'" :allCode='allCode' :type="DIGITAL" @pickPromotions="morePickPromotions(DIGITAL)"></VoucherDialog>
          </div>
        </div>
			</div>
		</el-dialog>
		<el-dialog
			title="Check CIC"
			:visible.sync="checkCicDialogVisible"
			center
			style="right: 12px !important;"
			append-to-body
		>
			<div class="row">
				<div class="embed-responsive embed-responsive-16by9">
					<iframe class="embed-responsive-item video-cic" src="/media/video/cic.mp4"
									allowfullscreen></iframe>
				</div>
			</div>
		</el-dialog>
		<el-dialog
			class="responsive"
			title="Thông báo tính năng mới"
			:visible.sync="dialogMessageFeatureNew">
			<p>Phiếu đăng ký học tập nhãn Edutalk mới bổ sung thông tin quà tặng:</p>
			<img class="text-center" v-bind:src="'/media/images/contracts/feature-new.png'"/>
			<p style="max-width: 950px; word-break: break-word" class="font-weight-bold mt-4">Hãy chắc chắn rằng bạn đã chọn
				đúng cả voucher và quà tặng cho khách hàng!</p>
			<p style="max-width: 950px; word-break: break-word">Thông tin voucher và quà tặng tại phiếu đăng ký học tập đều
				được gửi tới khách hàng xác nhận và là cơ sở để bộ phận vận hành trao quà cho học viên.</p>
			<p style="max-width: 950px; word-break: break-word">Vì vậy hãy đảm bảo bạn đã tư vấn & tạo phiếu đăng ký học tập
				đúng theo chương trình khuyến mại của tháng nhé!</p>
			<span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogMessageFeatureNew = false">Đã hiểu</el-button>
  </span>
	  </el-dialog>
    <!-- End   -->
<!--    modal cảnh báo nhập SDT phụ huynh -->
    <el-dialog
        title="Thông báo"
        :visible.sync="checkDialogSDTPhuHuynh"
        class="responsive letter-spacing"
    >
      <p class="font-size">
        Hãy chắc chắn rằng bạn đã nhập đúng Số điện thoại Phụ huynh! <br><br>

        Phụ huynh là người đại diện pháp lý có đủ năng lực hành vi dân sự để thực hiện giao dịch mua bán, có đầy đủ quyền và nghĩa vụ được quy định theo điều khoản dịch vụ này (bao gồm các điều khoản về chính sách và điều khoản dịch vụ, thanh toán, học tập, được chăm sóc và quyết định về thông tin bảo hành, bảo lưu, chuyển nhượng, ...) <br><br>
        Nếu TVV nhập thông tin SĐT phụ huynh không đúng, bị phụ huynh hoặc bất kỳ bên thứ 3 nào phản ánh dẫn đến tranh chấp hoặc phiếu đăng ký học tập bị vô hiệu, gây thiệt hại cho khách hàng và công ty thì TVV phải chịu hoàn toàn trách nhiệm trước pháp luật do việc nhập thông tin không đúng, và bồi thường toàn bộ thiệt hại gây ra, kể cả khi TVV đã nghỉ việc. <br><br>
        Trung tâm sẽ tra cứu, nếu phát hiện vi phạm tiêu chuẩn "Nhập sai thông tin" tạo Phiếu đăng ký học tập, TVV sẽ cần chịu các trách nhiệm sau đây:<br>
        + Vi phạm lần 1: phạt 200.000 và lập biên bản <br>
        + Vi phạm lần 2: phạt 500.000 và lập biên bản <br>
        + Vi phạm lần 3: đề xuất tước quyền tư vấn viên
      </p>
      <div class="d-flex justify-content-center">
        <el-button class="bg-violet text-white" @click="checkDialogSDTPhuHuynh = false">
          Đã hiểu
        </el-button>
      </div>
    </el-dialog>
  </ValidationObserver>


</template>

<script>
import {GET_CENTERS} from "@/core/services/store/center/center.module";
import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
import {GET_COURSES} from "@/core/services/store/course/course.module";
import {FIND_PROMOTION, LIST_VOUCHER} from "@/core/services/store/contract/contract.module";
import {CUSTOMER_FIND} from "@/core/services/store/customer/customer.module";
import {CONTRACT_ONLINE_GET_TUITION} from "@/core/services/store/contract/contractOnline.module";
import moment from "moment-timezone";
import {CENTER_EDUTALK} from "@/core/config/center/centerOption";
import {DAI_HOC, EDUCATIONS, THCS, THPT} from "../../../../core/config/contract/contractOnlineOption";
import {GET_SCHOOL_ALL} from "../../../../core/services/store/contract/school.module";
import {GET_PROVINCES} from "../../../../core/services/store/public/provinces.module";
import {LIST_GIFT} from "../../../../core/services/store/contract/contract.module";
import {TYPE_GIFT, TYPE_VOUCHER} from "../../../../core/config/promotionOption";
import GiftDialog from "./GiftDialog";
import {CHECK_PARENT} from "../../../../core/services/store/user/users.module";
import {Code_Model} from "@/views/pages/customer/models/Code_Model";
import {FLASH_SALE, MONEY, OTHER_SCHOOL, PERCENT, PERIODIC_PROMOTIONS, SCHOOL_LINK
} from "@/core/config/voucher_code/voucher_code";
import VoucherDialog from "@/views/pages/customer/components/VoucherDialog.vue";
import {DIGITAL} from "../../../../core/config/voucher_code/voucher_code";

export default {
	name: "RegisterStudyForm",
	components: {VoucherDialog, GiftDialog},
	props: {
		errorsForm: {
			type: Object,
			default: () => {
				return null;
			}
		},
		contract_prop: {
			type: Object,
			default: () => {
				return null;
			}
		},
		is_show: {
			type: Boolean,
			default: () => {
				return false;
			}
		}
	},
	data() {
		return {
			count_unique: 0,
			combo_image: `${process.env.VUE_APP_BASE_URL}media/svg/combo.svg`,
			voucher_image: `${process.env.VUE_APP_BASE_URL}media/svg/voucher.svg`,
			total: 0,
			total_promotions: 0,
			total_course: 0,
			total_need: 0,
			promotionDetail: [],
			listPromotion: [],
			listVoucher: [],
			listGift: [],
			list_promotions_selected: [],
			giftDialogVisible: false,
			voucherDialogVisible: false,
			checkCicDialogVisible: false,
			detailPromotionDialogVisible: false,
			searchVoucher: '',
			customer: null,
			dataSearchGift: null,
			listGiftSearch: [],
			contract: {
				id: '',
				uuid: '',
				uuid_secret: '',
				is_confirm: 0,
				tuition_payment_id: '',
				is_older: true,
				name: '',
				phone: '',
				phone_other: '',
				email: '',
				birthday: '',
				center_id: '',
				branch_id: '',
				customer_id: this.$route.query.customer_id,
				total: '',
				total_need: 0,
				total_promotions: 0,
				courses: [
					{
						id: "",
						name: "",
						fee: 0
					}
				],
				student_register_courses : [
				 {
				   course: {},
				   fee: '',
				 }
				],
				list_promotions: [],
				gift_id: [],
				parent: {
					type: 'bo',
					other_value: '',
					name: '',
					phone: '',
					phone_other: '',
				}
			},
			gift_select: null,
			courses: [],
			centers: [],
			branches: [],
			tuitions: [],
			type: [],
			TYPE_VOUCHER: TYPE_VOUCHER,
			TYPE_GIFT: TYPE_GIFT,
			dialogMessageFeatureNew: false,
			schools: [],
			provinces: [],
      checkDialogSDTPhuHuynh: false,
      educations: EDUCATIONS,
      loadingVoucherGift: false,
      PERCENT:PERCENT,
      MONEY: MONEY,
      SCHOOL_LINK: SCHOOL_LINK,
      FLASH_SALE: FLASH_SALE,
      PERIODIC_PROMOTIONS: PERIODIC_PROMOTIONS,
      OTHER_SCHOOL: OTHER_SCHOOL,
      DIGITAL : DIGITAL,
      allCode: [],
      numberCode:0,
      listCodeSearch:{},
      arrayVoucher: [],
      digitalBranch: 182

    }
	},
	watch: {
		contract: {
			handler(newVal, oldVal) {
				this.contract.list_promotions = this.list_promotions_selected;
        console.log(this.contract,'aaaaaa');
				this.contract.total = this.total;
        // this.gift_select = this.contract?.gifts[0];
				this.$emit('dataContract', this.contract);
			},
			deep: true,
			immediate: true
		},
		contract_prop: {
			handler(newVal, oldVal) {
				this.contract = this.contract_prop;
				this.calcPrice();
				// this.openListVoucher();
				this.getCenter();
				this.getBranch();
				this.getCourse();
				this.getTuition();
				// this.getListGift();
				this.list_promotions_selected = this.contract && this.contract.list_promotions ? this.contract.list_promotions : [];
			},
			deep: true,
			immediate: true

		},
		errorsForm: {
			handler() {
				if (_.size(this.errorsForm)) {
					this.$refs.form.setErrors(
						this.errorsForm
					);
				}
			},
			deep: true,
			immediate: true
		}
	},
	async mounted() {
		this.dialogMessageFeatureNew = false;
		this.type = [];
		await this.getCenter();
		await this.getBranch();
		await this.getCourse();
		await this.getCustomer();
		await this.handleBirthday();
		this.getTuition();
		this.getProvince();
		await this.getSchool();
	},
	methods: {
		handleEducation() {
			this.contract.school_id = '';
			this.getSchool();
		},
		getProvince() {
			this.$store.dispatch(GET_PROVINCES).then((data) => {
				this.provinces = data.data;
			}).finally(()=>{
         this.openListVoucher()
      })
		},
		async getSchool() {
			if (!this.contract.education_id || !this.contract.province_id) {
				return false;
			}
			await this.$store.dispatch(GET_SCHOOL_ALL, {
				education_id: this.contract.education_id,
				province_id: this.contract.province_id,
			}).then((data) => {
				this.schools = data.data;
			})
		},
		searchGift() {
			this.listGift = this.listGiftSearch.filter((gift) => {
				return gift.name.toUpperCase().includes(this.dataSearchGift.toUpperCase())
			})
		},
		getQuantityCourse() {
			return this.total_course = this.contract.courses.filter((item) => {
				return item.id > 0;
			}).length;
		},
		checkUniquePhone() {
      if (this.contract.phone && this.contract.phone !== this.contract.parent.phone) {
        this.$store.dispatch(CHECK_PARENT, {phone: this.contract.parent.phone}).then((data) => {
          if (data.data) {
            this.$message.error('Số điện thoại của phụ huynh không được trùng với số điện thoại của nhân sự công ty. Vui lòng nhập lại đúng thông tin phụ huynh.');
          }
        });
      }
		},
		getTuition() {
			this.$store.dispatch(CONTRACT_ONLINE_GET_TUITION).then((data) => {
				this.tuitions = data.data;
			});
		},
		async getCenter() {
			await this.$store.dispatch(GET_CENTERS, {
				limit: 10000,
				is_edutalk: CENTER_EDUTALK,
				status: 1
			}).then((data) => {
				this.centers = data.data.data;
			});
		},
		async getBranch() {
			await this.$store.dispatch(GET_BRANCHES, {
				limit: 10000,
				center_id: this.contract.center_id,
				type: this.type,
				noibo: true
			}).then((data) => {
				this.branches = data.data;
			});
		},
		async getCourse() {
			await this.$store.dispatch(GET_COURSES, {
				per_page: 10000,
				center_id: this.contract.center_id,
				branch_id: this.contract.branch_id,
				status: 1,
			}).then((data) => {
				this.courses = data.data.data;
			});
		},
		async getCustomer() {
			let id = this.$route.query.customer_id ? parseInt(this.$route.query.customer_id) : '';
			await this.$store.dispatch(CUSTOMER_FIND, id).then((data) => {
				this.customer = data.data;
				this.contract.name = this.customer.name;
				this.contract.phone = this.customer.phone;
				this.contract.email = this.customer.email;
				this.contract.birthday = (this.customer.ngay_sinh == '0000-00-00' || !this.customer.ngay_sinh) ? '' : this.customer.ngay_sinh
        this.contract.branch = this.customer?.user_intro_branch_id
			});
		},
		summit() {
		},
		addNewCourse: function () {
			this.contract.courses.push({
				id: '',
				name: '',
				fee: '',
			});
			this.contract.student_register_courses.push({
      		    course: {},
      		    fee: '',
      		});
		},
		setCourseDefault() {
			return [
				{
					id: '',
					name: '',
					fee: '',
				}
			]
		},
		setRegisterCourseDefault() {
			return [
				{
					course: {},
					fee: '',
				}
			]
		},
		removeCourse(index) {
			this.resetGift();
			this.list_promotions_selected = [];
			this.checkVoucherSelected();
			this.contract.courses.splice(index, 1);
			this.contract.student_register_courses.splice(index, 1);
			this.calcPrice();
		},
		handleCourse(event) {
			this.list_promotions_selected = [];
			this.resetGift();
			this.resetVoucher();
			this.checkVoucherSelected();
			this.addValue(event);
			this.calcPrice();
			this.$forceUpdate();
			this.getListGift();
			this.openListVoucher();
		},
		handleCenter() {
			this.type = [2, 3];
			this.list_promotions_selected = [];
			this.branches = [];
			this.contract.courses = this.setCourseDefault();
			this.contract.student_register_courses = this.setRegisterCourseDefault();
			this.contract.branch_id = '';
			this.getCourse();
			this.openListVoucher();
			this.getBranch();
		},
		handleBranch() {
			this.contract.courses = this.setCourseDefault();
			this.contract.student_register_courses = this.setRegisterCourseDefault();
			this.list_promotions_selected = [];
			this.resetGift();
			this.cancelGift({});
			this.openListVoucher();
			this.getListGift();
		},
		handleTuitionPayment() {
			this.resetGift();
			this.resetVoucher();
			this.getListGift();
			this.openListVoucher();
		},
		searchVoucherByName() {
			if (this.searchVoucher.length == 0) {

			} else {
        [FLASH_SALE,PERIODIC_PROMOTIONS,SCHOOL_LINK,OTHER_SCHOOL].forEach((value)=>{
          this.listCodeSearch[value] = this.allCode[value].filter((code) =>
         	code.name.toLowerCase().includes(this.searchVoucher.toLowerCase())
         );
        })
        console.log(this.listCodeSearch)
			}
		},
		calcPrice() {
			this.total = 0;
			let total_normal = 0;
			let courses = this.contract.courses;
			let register_courses = this.contract.student_register_courses;
			let courses_flash_sale = [];
			// tìm trong promotion course flash sale
			for (let i = 0; i < this.list_promotions_selected.length; i++) {
				if (this.list_promotions_selected[i].is_flash_sale == 1 && this.list_promotions_selected[i].course.id) {
					courses_flash_sale.push(this.list_promotions_selected[i].course.id)
				}
			}
			for (let i = 0; i < register_courses.length; i++) {
				this.total = this.total + register_courses[i].fee;
				if (!courses_flash_sale.includes(register_courses[i].course.id)) {
					total_normal = total_normal + register_courses[i].fee;
				}
			}
			// Cacl promotion
			let value_promotions_total = 0;
			this.list_promotions_selected.map((promo) => {
				if (promo.unit == 1) {
					// tiền mặt
					value_promotions_total += promo.value;
					promo.value = promo.value;

				} else {
					// phần trăm
					if (promo.is_flash_sale == 1) {
						// flash sale
						value_promotions_total += promo.course.fee * promo.value / 100;
					} else {
						value_promotions_total += total_normal * promo.value / 100;
						// promo.value = (this.total * promo.value) / 100;
					}
				}
			});
			this.total_promotions = value_promotions_total;
			this.contract.total_promotions = value_promotions_total;
			this.total_need = this.total - value_promotions_total;
			this.contract.total_need = this.total - value_promotions_total;
		},
		addValue(event) {
			this.courses.map((course) => {
				this.contract.courses.map((item, index) => {
					if (course.id == item.id) {
						this.contract.courses[index].name = course.name;
						this.contract.courses[index].fee = course.fee;
						this.contract.courses[index].id = course.id;
						this.contract.courses[index].promotion_flash_sale = course.promotion_flash_sale;
					}
				})
			})
			let found = this.contract.courses.findIndex((element) => element.id == event.id);
			this.contract.student_register_courses[found].fee = event.fee;
			this.contract.student_register_courses[found].course = event;
			this.checkUniqueCourse(event);
		},
		checkUniqueCourse(event) {
			let courses = this.contract.courses;
			let count_unique = 0;
			let index = 0;
			for (let i = 0; i <= courses.length; i++) {
				let item = courses[i];
				if (item && item.id) {
					if (item.id === event.id) {
						index = i;
						count_unique++;
					}
				}
			}
			if (count_unique > 1) {
				this.contract.courses.splice(index, 1)
				this.contract.student_register_courses.splice(index, 1)
				this.$message.error('Khóa học đã được chọn');
				return false;
			}
		},
		openListGift() {
			this.giftDialogVisible = true;
		},
		viewVoucher() {
			this.voucherDialogVisible = true;
			this.openListVoucher();
		},
		async openListVoucher() {
            this.loadingVoucherGift = true;
			this.searchVoucher = '';
			let courseSend = [];
			_.each(this.contract.courses, function (course) {
				courseSend.push(course.id);
			});
			await this.$store.dispatch(LIST_VOUCHER, {
				'name': this.searchVoucher,
				'branch_id': this.contract.branch_id,
				'center_id': this.contract.center_id,
				'course_ids': courseSend,
				'tuition_payment_id': this.contract.tuition_payment_id,
				'quantity_course': this.getQuantityCourse(),
			}).then((response) => {
				this.listVoucher = response.data;
        this.getListGift()
				this.checkVoucherSelected();
			});
			await this.checkVoucherSelected();
		},
		getListGift() {
            let courseSend = [];
            _.each(this.contract.courses, function (course) {
                courseSend.push(course.id);
            });

			this.listGift = [];
			if (this.contract.branch_id) {
				this.$store.dispatch(LIST_GIFT, {
					'branch_id': this.contract.branch_id,
					'quantity_course': this.getQuantityCourse(),
					'tuition_payment_id': this.contract.tuition_payment_id,
                    course_ids: courseSend
				}).then((response) => {
					this.listGift = response.data;
                    let giftModel = this.listGift.map((voucher)=>{
                        return new Code_Model(voucher.program_id,voucher?.branch_id,voucher?.course,voucher?.course_ids,voucher?.gift,voucher?.id,voucher?.is_center,voucher?.type,voucher?.value,voucher?.name,0,voucher?.start_date,voucher?.end_date,voucher?.description, voucher.unit,voucher.value,true,true)
                  })
                  let voucherModel = this.listVoucher?.map((voucher)=>{
                     return new Code_Model(voucher.program_id,voucher?.branch_id,voucher?.course,voucher?.course_ids,voucher?.gift,voucher?.id,voucher?.is_center,voucher?.type,voucher?.value,voucher?.name,+voucher?.is_flash_sale,voucher?.start_date,voucher?.end_date,voucher?.description, voucher.unit,voucher.amount)
                     })
                  let ListProgramUsed = this.list_promotions_selected.length === 0 ? [] : this.list_promotions_selected.map((promotion)=>promotion.program_id)
                  let code = (voucherModel.concat(giftModel)).map((voucher)=> {
                    if(this.list_promotions_selected.find((e)=>e.id ==voucher.id ) || this.contract.gift_id.find((b)=> b == voucher.id )){
                      return  {...voucher,selected:true}
                    }
                    return ListProgramUsed.length === 0 ?voucher: ListProgramUsed.includes(voucher.program_id)?voucher.program_id == SCHOOL_LINK?{...voucher,hidden:true}:voucher:{...voucher,hidden:true}

                  })

                  this.allCode = _.groupBy(code,({program_id})=>program_id)
                            this.listGiftSearch = [...this.listGift];
                            this.setGiftSelect(this.listGift);
                        }).finally(()=>{
                  this.loadingVoucherGift = false
        });
			}
		},
		showDetailPromotion(item) {
            this.promotionDetail = item;
			this.detailPromotionDialogVisible = true;
			// this.$store.dispatch(FIND_PROMOTION, item.id).then((response) => {
			// 	this.promotionDetail = response.data;
			// });
		},
		pickPromotions(event) {
			this.dataSearchGift = ''
			if (!event.amount && event.amount != null) {
				this.$message.error('Voucher bạn chọn đã hết lượt sử dụng');
				return;
			}
			this.giftDialogVisible = false;

			this.promotion_id = event.id;
			let temp = this.list_promotions_selected;
			_.each(temp, function (value, key) {
				if (event.type === 1 || event.type === 2) {
					if (value.type === 1 || value.type === 2) {
						temp.splice(key, 1);
					}
				}
			});
			temp.push(event);
			this.list_promotions_selected = temp;
			this.calcPrice();
			if (event.type === TYPE_VOUCHER) {
				this.checkVoucherSelected();
			}
			this.arrayVoucher.push(event.id)
		},
        morePickPromotions(key){
          this.list_promotions_selected = []
          this.contract.gift_id = [];
          this.gift_select = null;
          this.allCode[key].filter((code)=>code.selected === true).forEach((item)=>{
            item.isUseGift ? this.pickGift(item) : this.pickPromotions(item)
          })
        },
		pickGift(event) {
			this.dataSearchGift = ''
			this.contract.gift_id.push(event.id);
			this.gift_select = event;
			this.unPickPromotions(event);
		},
		cancelGift(item) {
			this.dataSearchGift = ''
			this.contract.description = '';
			this.contract.gifts = [];
			this.resetGift();
			this.unPickPromotions(item);
		},
		resetGift() {
			this.contract.gift_id = [];
			this.gift_select = null;
		},
		resetVoucher() {
			this.list_promotions_selected = [];
			this.contract.list_promotions = [];
		},
		unPickPromotions(data) {
			this.list_promotions_selected = this.list_promotions_selected.filter(item => item.id !== data.id);
			this.checkVoucherSelected();
			this.calcPrice();
		},
		checkVoucherSelected() {
			let temp = this.list_promotions_selected;
			let arr_FS = [];
			temp.map(function (item) {
				if (item.is_flash_sale == 1 && item.course) {
					arr_FS.push(item.course.id)
				}
			});

			this.listVoucher.map(function (item) {
				if (item.is_flash_sale == 1 && arr_FS.length > 0) {
					item.is_selected_FS = 1;
				} else {
					item.is_selected_FS = 0;
				}
				let index_selected = temp.findIndex(e => e.id === item.id);
				if (index_selected >= 0) {
					item.is_selected = 1;
				} else {
					item.is_selected = 0;
				}
			});
		},
		handleBirthday() {
			if (!this.contract.birthday) {
				this.contract.is_older = true;
				return 1;
			}
			let age = moment().diff(this.contract.birthday, 'years', true);
			if (age >= 15) {
				this.contract.is_older = true;
			} else {
				this.contract.is_older = false;
			}
		},
		setGiftSelect(listGift) {
			let gift_id = this.contract.gift_id[0];
			for (let i = 0; i < listGift.length; i++) {
				if (listGift[i].id === gift_id) {
					this.gift_select = listGift[i];
				}
			}
		}
	},
	computed: {
		numberGift() {
			return this.listGift.length;
		},
		titleDetail() {
			if (this.promotionDetail.type === TYPE_VOUCHER) {
				return "Chi tiết voucher";
			} else {
				return "Chi tiết quà tặng";
			}
		},
		titlePriceDetail() {
			if (this.promotionDetail == this.TYPE_VOUCHER) {
				return 'Giảm ngay:';
			} else {
				return "Giá trị quy đổi tiền mặt:";
			}
		},
		titleDesc() {
			if (this.promotionDetail == this.TYPE_VOUCHER) {
				return 'Chi tiết quà tặng :';
			} else {
				return "Mô tả voucher";
			}
		},
		disableSelectSchool() {
			if (this.contract.education_id && this.contract.province_id) {
				return true;
			}
			return false;
		},
		isShowProvince() {
			if ([THPT, DAI_HOC].includes(this.contract.education_id)) {
				return true;
			}
			return false;
		},
		isThpt() {
			if ([THCS, THPT].includes(this.contract.education_id)) {
				return true;
			}
			return false;
		},
    promotionTotal(){
      if(+this.contract.branch === this.digitalBranch){
        return this.listVoucher.length + this.listGift.length
      }
      return  (this.listVoucher.length + this.listGift.length) - this.allCode[DIGITAL]?.length
    }
	}
}
</script>

<style scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
	width: 100%;
}

.el-select {
	width: 100%;
}

.video-cic {
	transform: rotate(360deg); /* Equal to rotateZ(45deg) */
	background-color: pink;
}

</style>
<style>
#voucher .el-dialog--center {
	width: 75%;
}

#voucher .el-dialog__body {
	text-align: center;
}

#promotion .el-dialog--center {
	width: 75%;
}

#promotion .el-dialog__body {
	text-align: center;
}

@media (min-width: 1024.5px) {
	#voucher .el-dialog--center {
		width: 40%;
	}


	#promotion .el-dialog--center {
		width: 40%;
	}
}
.bg-violet {
  background-color: #472f92;
}
.letter-spacing {
  letter-spacing: 0px !important;
}
.font-size {
  font-size: 15px !important;
}
</style>
