<template xmlns="http://www.w3.org/1999/html">
  <div class="pl-4 pt-4 font-light border border-t-0 border-gray-200 dark:border-gray-700 accordion-item">
    <ol class=" font-bold">
      <li style="list-style-type:decimal">
        <h3 style="text-align:justify"><span><strong>Chương trình học</strong></span></h3>
      </li>
    </ol>

    <h4 class="font-bold" style="text-align:justify"><span><strong>1.1. Lộ trình học </strong></span></h4>

    <p style="text-align:justify"><span>Khách hàng được đào tạo theo một lộ trình cụ thể trong từng giờ học đối với mỗi buổi học trên lớp và được hướng dẫn tự học tại nhà, đảm bảo được giảng dạy đúng số giờ học trên lớp của khóa học.</span>
    </p>
    <p> </p>
    <table cellspacing="0" style="border-collapse:collapse; border:none; width:622px">
      <tbody>
      <tr class="font-bold">
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:130px">
          <p style="margin-left:-4px; margin-right:-4px; text-align:center"><span><strong>Loại khoá học</strong></span>
          </p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:83px">
          <p style="margin-left:-4px; margin-right:-4px; text-align:center">
            <span><strong>Tên khóa học</strong></span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:187px">
          <p style="margin-left:-4px; margin-right:-4px; text-align:center">
            <span><strong>Trình độ đầu vào</strong></span></p>

        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:126px">
          <p style="margin-left:-4px; margin-right:-4px; text-align:center">
            <span><strong>Trình độ đầu ra</strong></span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:96px">
          <p style="margin-left:-4px; margin-right:-4px; text-align:center">
            <span><strong>Thời lượng buổi học</strong></span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:96px">
          <p style="margin-left:-4px; margin-right:-4px; text-align:center">
            <span><strong>Số buổi học</strong></span></p>
        </td>
      </tr>
      <tr>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:130px">
          <p style="text-align:center"><span>Giao tiếp</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:83px">
          <p style="text-align:center"><span>Khóa giao tiếp nền tảng</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:187px">
          <p style="text-align:center"><span>Pre - A1</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:126px">
          <p style="text-align:center"><span>A1
(Tương đương 7/10 điểm bài thi cuối kì)
</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>90 phút /1 buổi</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>24</span></p>
        </td>
      </tr>
      <tr>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align : middle;text-align:center; width:96px"
            rowspan="4">
          <p style="text-align:center"><span>IELTS</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>Khóa PRE IELTS</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>A1</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>A2 IELTS 3.5</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>90 phút /1 buổi</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>24</span></p>
        </td>
      </tr>
      <tr>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>Khóa IELTS 3.5 - 4.5</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>3.5</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>4.5</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>90 phút /1 buổi</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>29</span></p>
        </td>
      </tr>
      <tr>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>Khóa IELTS 4.5 - 5.5</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>4.5</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>5.5</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>180 phút /1 buổi</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>24</span></p>
        </td>
      </tr>
      <tr>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>Khóa IELTS 5.5 -6.5</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>5.5</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>6.5</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>180 phút /1 buổi</span></p>
        </td>
        <td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:96px">
          <p style="text-align:center"><span>19</span></p>
        </td>
      </tr>
      </tbody>
    </table>

    <p> </p>

    <h4 class="font-bold" style="text-align:justify"><span><strong>1.2. Nội dung khóa học</strong></span><span> </span>
    </h4>

    <p style="text-align:justify">
      <span>
        Nội dung khóa học mà Khách hàng đăng ký học dựa trên các giáo trình được dùng cho việc giảng dạy tiếng Anh của các nhà xuất uy tín trên thế giới, bao gồm nhưng không giới hạn các nhà sản xuất sau: Cambridge, Pearson, Oxford, Longman và được biên soạn bởi đội ngũ có chức năng nghiên cứu và phát triển khóa học của IELTS Mentor để phù hợp với trình độ của khóa học.
      </span>
    </p>

    <h4 class="font-bold" style="text-align:justify"><span><strong>1.3. Lịch học</strong></span></h4>

    <p><span>- Khách hàng được đào tạo chính 02 buổi/ tuần;</span></p>
    <p><span>- Lịch học của Khách hàng được Trung tâm sắp xếp cố định theo 02 loại lịch học sau đây:</span></p>
    <p><span>     + Lịch học được xếp theo các ca học: Thứ 2 – Thứ 5; Thứ 3 – Thứ 6; Thứ 4 -Thứ 7.</span></p>
    <p><span>     + Lịch học được xếp theo các ca học: Thứ 2 – Thứ 6; Thứ 3 – Thứ 5; Thứ 4 -Thứ 7. </span></p>

    <p><span>- Với từng khu vực địa lý theo Tỉnh/ Thành, lịch học của Khách hàng được Trung tâm sắp xếp cố định theo 01 trong 02 loại lịch học trên. Ví dụ: </span>
    </p>
    <p>
      <span>     + Lịch học khu vực Hà Nội được xếp theo các ca học: Thứ 2 – Thứ 5; Thứ 3 – Thứ 6; Thứ 4 -Thứ 7. </span>
    </p>
    <p>
      <span>     + Lịch học khu vực Hồ Chí Minh được xếp theo các ca học: Thứ 2 – Thứ 6; Thứ 3 – Thứ 5; Thứ 4 -Thứ 7. </span>
    </p>

    <p><span>- Ca 1: Bắt đầu từ 18h00 đến 19h30; Ca 2: Bắt đầu từ 19h45 đến 21h15. (Đối với khóa giao tiếp và khóa IELTS từ Pre IELTS - IELTS 4.5); Khóa IELTS từ 4.5 – 6.5 bắt đầu từ 18h00 đến 21h00.</span>
    </p>

    <h4 class="font-bold" style="text-align:justify"><span><strong>1.4. Tài liệu cung cấp</strong></span></h4>


    <p><span>- Tài liệu học tập (sách, sổ) đầy đủ;</span></p>
    <p><span>- Trao chứng nhận đã hoàn thành khóa học cho Khách hàng khi khóa học kết thúc.</span></p>

    <h4 class="font-bold" style="text-align:justify"><span><strong>1.5. Kiểm tra giữa khóa, cuối khóa</strong></span>
    </h4>


    <p><span>- Giữa và cuối mỗi khóa học, Trung tâm có quyền tổ chức kiểm tra để đánh giá trình độ học viên trong khóa. Kỳ kiểm tra cuối khóa sẽ do bộ phận kiểm tra đánh giá trực tiếp kiểm tra trình độ.
      Khách hàng cần tham gia buổi kiểm tra cuối khóa mới được đánh giá là hoàn thành khóa học và được tham gia học khóa học tiếp theo nếu đủ điểm đầu vào.</span>
    </p>
    <p>- Trường hợp Khách hàng không tham gia làm bài kiểm tra giữa khóa, cuối khóa học vì có lý do chính đáng và được
      Trung tâm chấp nhận, bộ phận kiểm tra đánh giá của Trung tâm sẽ sắp xếp lịch cho Khách hàng kiểm tra lại miễn phí
      trong vòng một tuần tính từ ngày kiểm tra trình độ lần 1. Trong đó, các lý do nghỉ kiểm tra chính đáng được Trung
      tâm chấp nhận bao gồm:</p>
    <p class="pl-5">
        <span>
          + Có vấn đề sức khỏe dẫn đến việc không thể tham gia kiểm tra và có giấy xác nhận của cơ quan y tế;
        </span>
    </p>
    <p class="pl-5">
        <span>
          + Lý do liên quan đến công việc (có giấy xác nhận của công ty), học tập (có xác nhận của trường học) dẫn đến việc không thể tham gia kiểm tra.
        </span>
    </p>
    <p>- Nếu Khách hàng không sắp xếp tham gia kiểm tra được trong thời gian này, mức phí phục vụ Khách hàng thi bổ sung
      là 100.000 VNĐ (Một trăm nghìn đồng Việt Nam) cho mỗi lần thi.</p>
    <p>- Trường hợp Khách hàng không tham gia làm bài thi giữa khóa, cuối khóa không thuộc lý do chính đáng được Trung
      tâm chấp nhận; hoặc có nhu cầu thi lại, mức phí phục vụ Khách hàng thi
      bổ sung là 100.000 VNĐ (Một trăm nghìn đồng Việt Nam) cho mỗi lần thi. </p>
    <ol class=" font-bold" start="2">
      <li style="list-style-type:decimal">
        <h3 style="text-align:justify"><span><strong>Chương trình gia sư</strong></span></h3>
      </li>
    </ol>

    <p class="font-bold" style="text-align:justify"><span><strong>2.1. Mô tả</strong></span></p>

    <p>
      <span>- Mỗi Khách hàng đăng ký học tại IELTS Mentor có tổng 20 buổi gia sư.</span>
    </p>
    <p><span>- Lệ phí: Miễn phí</span></p>

    <p class="font-bold" style="text-align:justify"><span><strong>2.2. Tần suất học</strong></span></p>
    <p><span>- Tần suất học gia sư: Tối đa 2 buổi/ 1 tháng</span></p>

    <p class="font-bold" style="text-align:justify"><span><strong>2.3. Nội dung chương trình</strong></span></p>

    <p><span>- Chương trình bổ trợ “Next level”</span></p>
    <p><span>     + Đối tượng: Học viên kết thúc khóa học, chuẩn bị học khóa mới.</span></p>
    <p><span>     + Nội dung: Ôn luyện các kỹ năng theo Hệ thống giáo trình Next Level.</span></p>
    <p><span>     + Thời gian: Theo thời gian Trung tâm sắp xếp</span></p>
    <p><span>     + Số lượng: 05 buổi</span></p>
    <p><span>     + Thời lượng: 90 phút</span></p>
    <p><span>     + Hình thức học: Online hoặc Offline theo nhóm</span></p>
    <p><span>- Chương trình Gia sư bổ trợ năng lực</span></p>
    <p><span>     + Đối tượng: Học viên vắng mặt hoặc điểm kiểm tra thấp</span></p>
    <p><span>     + Thời gian: Học viên tự lựa chọn khung thời gian phù hợp</span></p>
    <p><span>     + Số lượng: 10 buổi</span></p>
    <p><span>     + Thời lượng: 60 phút</span></p>
    <p><span>     + Hình thức học: Online 1vs1</span></p>
    <p><span>- Chương trình Gia sư theo nhu cầu</span></p>
    <p><span>     + Đối tượng: Học viên có nhu cầu ôn thi trên trường và nhu cầu cá nhân</span></p>
    <p><span>     + Thời gian: Học viên tự lựa chọn khung thời gian phù hợp</span></p>
    <p><span>     + Số lượng: 05 buổi</span></p>
    <p><span>     + Thời lượng: 60 phút</span></p>
    <p><span>     + Hình thức học: Online 1vs1</span></p>

    <ol class=" font-bold" start="3">
      <li style="list-style-type:decimal">
        <h3 style="text-align:justify"><span><strong>Chương trình Workshop, Câu lạc bộ</strong></span></h3>
      </li>
    </ol>

    <p class="font-bold" style="text-align:justify"><span><strong>3.1. Mô tả</strong></span></p>

    <p style="text-align:justify"><span>- Mỗi quý IELTS Mentor sẽ tổ chức 1-2 buổi Câu lạc bộ và 1 -2 buổi Workshop cho các Khách hàng.</span>
    </p>
    <p style="text-align:justify"><span>- Lệ phí: Miễn phí</span></p>
    <p class="font-bold" style="text-align:justify"><span><strong>3.2 Hình thức học</strong></span></p>
    <p><span>- Online hoặc Offline</span>
    </p>
    <p class="font-bold" style="text-align:justify"><span><strong>3.3 Thời lượng</strong></span></p>
    <p><span>- 120-180 phút/ 01 buổi</span>
    </p>
    <p class="font-bold" style="text-align:justify"><span><strong>3.4 Nội dung chương trình</strong></span></p>

    <p><span>- Chương trình học do Trung tâm sắp xếp.</span></p>
    <p><span>- Trung tâm sẽ thông báo tới Khách hàng về chủ đề và các thông tin liên quan đến chương trình Workshop, Câu lạc bộ tối thiểu 01 tuần trước ngày diễn ra chương trình.</span>
    </p>
    <p class="font-bold" style="text-align:justify"><span><strong>3.5 Thời gian</strong></span></p>
    <p><span>- Theo thời gian Trung tâm sắp xếp.</span></p>
    <p><span>- Trung tâm sẽ thông báo tới Khách hàng về thời gian chương trình Workshop, Câu lạc bộ tối thiểu 01 tuần trước ngày diễn ra chương trình.</span>
    </p>


    <ol class=" font-bold" start="4">
      <li style="list-style-type:decimal">
        <h3 style="text-align:justify"><span><strong>Chương trình Tư vấn học tập</strong></span></h3>
      </li>
    </ol>
    <p class="font-bold" style="text-align:justify"><span><strong>4.1. Mô tả</strong></span></p>

    <p><span>- Khách hàng sẽ được các Chuyên gia về Phương pháp học và Ngoại ngữ tư vấn về các vấn đề liên quan tới Học tập, Phương pháp học, Ôn luyện các kỳ thi…</span>
    <p><span>- Số buổi tối đa: 01 buổi/ tháng</span>
    <p><span>- Lệ phí: Miễn phí</span>
    <p class="font-bold" style="text-align:justify"><span><strong>4.2. Hình thức học̉</strong></span></p>
    <p><span>- Online</span>
    <p class="font-bold" style="text-align:justify"><span><strong>4.3. Thời lượng</strong></span></p>
    <p><span>- 30-60 phút/ 01 buổi</span>
    <p class="font-bold" style="text-align:justify"><span><strong>4.4. Nội dung chương trình</strong></span></p>
    <p><span>- Tùy theo các khó khăn mà Khách hàng gặp phải.</span>
    <p class="font-bold" style="text-align:justify"><span><strong>4.5. Thời gian</strong></span></p>
    <p><span>- Học viên tự lựa chọn khung thời gian phù hợp.</span>

    <ol class="pt-3 font-bold" start="5">
      <li style="list-style-type:decimal">
        <h3 style="text-align:justify"><span><strong>Chính sách bảo hành</strong></span></h3>
      </li>
    </ol>
    <p class="font-bold" style="text-align:justify">
      <span><strong>5.1. Quy định trong chính sách bảo hành</strong></span></p>
    <p><span>Khách hàng cam kết thực hiện đúng những quy định sau đây:</span>
    <p><span>- Đảm bảo đạt chuẩn theo yêu cầu đầu vào đối với khóa học đã đăng ký của IELTS Mentor. Trong đó, “Đầu vào khoá học” được hiểu là điểm của Khách hàng đối với bài thi kiểm tra đầu vào do IELTS Mentor tổ chức hoặc điểm thi cuối khóa (có giá trị trong 90 ngày kể từ ngày có điểm thi) của Khách hàng đối với khóa học của IELTS Mentor mà Khách hàng tham gia học trước đó.</span>
    <p><span>- Thanh toán đầy đủ phí dịch vụ theo phiếu đăng ký học tập tại IELTS Mentor;</span></p>
    <p><span>- Liên hệ trao đổi cùng với Trung tâm để được xếp lớp thời gian phù hợp nhất với Khách hàng;</span></p>
    <p><span>- Tham gia đầy đủ, nghiêm túc các buổi học theo quy định sau:</span></p>
    <p><span>     + Chỉ được nghỉ tối đa <span style="font-weight: bold">hai (02) buổi học</span>/ khóa học bao gồm có phép và không phép;</span>
    </p>
    <p><span>     + Không được vào lớp muộn quá 15 phút và/hoặc kết thúc lớp học sớm trước <span
        style="font-weight: bold">15 phút</span>, nếu vi phạm sẽ được coi là nghỉ buổi học đó;</span></p>
    <p><span>     + Hoàn thành đầy đủ 100% bài tập về nhà đúng hạn trên APP và giáo viên giao. Trong đó, sau mỗi buổi học, khi hoàn thành bài tập về nhà trên APP của Trung tâm đúng hạn, Khách hàng được giảng viên chấm và nhận xét chi tiết. Trường hợp gặp vấn đề về lỗi APP, Khách hàng cần liên hệ IELTS Mentor Support Centre trước thời hạn bài tập về nhà 02 tiếng để được hỗ trợ kịp thời.</span>
    </p>
    <p><span>     + Tham gia đầy đủ và làm bài trung thực các buổi kiểm tra giữa khóa và cuối khóa, buổi kiểm tra được xem như một buổi học chính thức.</span>
    </p>
    <p>
      <span>- Khách hàng phải tuyệt đối bảo mật, không tự ý cung cấp tài liệu nội bộ của IELTS Mentor ra bên ngoài;</span>
    </p>
    <p><span>- Trong quá trình sử dụng dịch vụ Khách hàng phải tuân theo các nội quy, nguyên tắc, quy chế Trung tâm và pháp luật hiện hành. Không được cung cấp thông tin sai lệch ảnh hưởng tới danh tiếng của IELTS Mentor;</span>
    </p>
    <p><span>- Khách hàng có nghĩa vụ tham gia lớp học mà IELTS Mentor đã liên hệ xếp lớp thành công. Sau khi Khách hàng đã đồng ý tham gia lớp học IELTS Mentor sắp xếp dựa trên sự đồng thuận của 02 bên, Khách hàng không được phép đổi khóa, chuyển lớp vì bất kỳ lý do gì.</span>
    </p>
    <p class="font-bold" style="text-align:justify"><span><strong>5.2. Nội dung chính sách bảo hành</strong></span>
    </p>
    <p><span>- Đối với khóa học IELTS cuối cùng trong Combo Khách hàng đăng ký, trong vòng tối đa 30 ngày liên tục kể từ khi kết thúc khoá học, nếu Khách hàng tham gia thi IELTS tại Hội đồng Anh hoặc Tổ chức IDP sau khi kết thúc khóa học IELTS; nhưng Khách hàng không đạt được điểm số tương đương với điểm chuẩn đầu ra của khóa học, IELTS Mentor sẽ xử lý như sau:</span>
    </p>
    <p><span>     <li style="list-style-type:disc"> Khách hàng được học lại miễn phí đối với khóa học gần nhất mà Khách hàng học của IELTS Mentor trước khi tham gia thi trong trường hợp Khách hàng thực hiện đúng, đầy đủ các nghĩa vụ tại mục 5.1. Quy định trong chính sách bảo hành của điều khoản dịch vụ này.
    Tại mỗi lần học lại, khi tham gia thi lại tại Hội đồng Anh hoặc Tổ chức IDP, nhưng không đạt được điểm số tương đương với điểm chuẩn đầu ra, Khách hàng tiếp tục được học lại miễn phí, trong trường hợp ở mỗi lần học lại, Khách hàng thực hiện đúng, đầy đủ các nghĩa vụ tại mục
      5.1. Quy định trong chính sách bảo hành.
    </li></span>
    </p>
    <p><span>     <li style="list-style-type:disc"> Tiến hành kiểm tra lại trình độ cho Khách hàng, hỗ trợ Khách hàng đăng ký học lại khóa học của IELTS Mentor phù hợp với trình độ sau kiểm tra và Khách hàng phải đóng 100% phí dịch vụ của khóa học theo mức giá Khách hàng lẻ niêm yết tại thời điểm đó trong trường hợp Khách hàng không đáp ứng đầy đủ các nghĩa vụ được quy định tại mục 5.1. Quy định trong chính sách bảo hành của điều khoản dịch vụ này nhưng có mong muốn học lại.</li></span>
    </p>
    <p><span>     + Trường hợp đặc biệt, trong vòng 30 ngày liên tục đó không có bất cứ kỳ thi IELTS nào được Hội đồng Anh và Tổ chức IDP tổ chức tại khu vực Tỉnh/ Thành mà Khách hàng đang sinh sống, Khách hàng có thêm 30 ngày để chuẩn bị tham gia thi IELTS ở các khu vực Tỉnh/ Thành lân cận;</span>
    </p>
    <p><span>     + Trường hợp Trung tâm được miễn trừ trách nhiệm: IDP và Hội đồng Anh tạm ngưng tổ chức kỳ thi IELTS trên tất cả Tỉnh/ Thành quá 60 ngày bắt đầu từ thời điểm IDP và Hội đồng Anh thông báo chính thức.</span>
    </p>
    <p><span>- Đối với các khóa IELTS không phải là khoá học cuối trong Combo của Khách hàng, nếu Khách hàng tham gia thi cuối khoá do Trung tâm tổ chức nhưng Khách hàng không đạt được điểm số tương đương với điểm chuẩn đầu ra của khoá học thì sẽ xử lý như sau:</span>
    </p>
    <p><span>     <li style="list-style-type:disc">
      Khách hàng được Trung tâm sắp xếp, tổ chức giảng dạy và ôn tập lại miễn phí đến khi đạt điểm số tương đương với điểm chuẩn đầu ra trước khi Khách hàng học lên khoá tiếp theo trong trường hợp Khách hàng thực hiện đúng, đầy đủ các nghĩa vụ tại mục 5.1. Quy định trong chính sách bảo hành của điều khoản dịch vụ này;
    </li></span>
    </p>
    <p><span>     <li style="list-style-type:disc"> Tiến hành kiểm tra lại trình độ cho Khách hàng, hỗ trợ Khách hàng đăng ký học lại khóa học của IELTS Mentor phù hợp với trình độ sau kiểm tra và Khách hàng phải đóng 100% phí dịch vụ của khóa học theo mức giá Khách hàng lẻ niêm yết tại thời điểm đó trong trường hợp Khách hàng không đáp ứng đầy đủ các nghĩa vụ được quy định tại mục 5.1. Quy định trong chính sách bảo hành của điều khoản dịch vụ này nhưng có mong muốn học lại.</li></span>
    </p>
    <p><span>- Đối với khoá giao tiếp, nếu Khách hàng tham gia kỳ thi cuối khoá do IELTS Mentor tổ chức sau khi kết thúc khóa học và không đạt được điểm chuẩn đầu ra của khoá đó thì IELTS Mentor sẽ xử lý như sau:</span>
    </p>
    <p><span>     <li style="list-style-type:disc"> Khách hàng được Trung tâm sắp xếp, tổ chức giảng dạy và ôn tập lại miễn phí đến khi đạt điểm số tương đương với điểm chuẩn đầu ra trước khi Khách hàng học lên khoá tiếp theo trong trường hợp Khách hàng thực hiện đúng, đầy đủ các nghĩa vụ tại mục 5.1. Quy định trong chính sách bảo hành của điều khoản dịch vụ này;
</li></span>
    </p>
    <p><span>     <li style="list-style-type:disc"> IELTS Mentor tiến hành kiểm tra lại trình độ cho Khách hàng, hỗ trợ Khách hàng đăng ký học lại khóa học của Trung tâm phù hợp với trình độ sau kiểm tra và Khách hàng phải đóng 100% phí dịch vụ của khóa học theo mức giá Khách hàng lẻ niêm yết tại thời điểm đó trong trường hợp Khách hàng không đáp ứng đầy đủ các nghĩa vụ được quy định tại mục 5.1. Quy định trong chính sách bảo hành của điều khoản dịch vụ này nhưng có mong muốn học lại. </li></span>
    </p>
    <p><span>- Trong trường hợp Khách hàng không tham gia làm bài kiểm tra cuối khóa học vì có lý do chính đáng và được Trung tâm chấp nhận, bộ phận kiểm tra đánh giá của Trung tâm sẽ sắp xếp lịch cho Khách hàng kiểm tra lại miễn phí trong vòng một tuần tính từ ngày kiểm tra trình độ lần 1. Nếu Khách hàng không sắp xếp tham gia kiểm tra được trong thời gian này, Khách hàng phải chịu mức phí thi bổ sung là 100.000 VNĐ (Một trăm nghìn đồng Việt Nam) cho mỗi lần thi. Trong đó, các lý do nghỉ kiểm tra chính đáng được Trung tâm chấp nhận bao gồm:</span>
    </p>
    <p>
      <span>     <li style="list-style-type:disc"> Có vấn đề sức khỏe dẫn đến việc không thể tham gia kiểm tra và có giấy xác nhận của cơ quan y tế;</li></span>
    </p>
    <p><span>     <li style="list-style-type:disc"> Lý do liên quan đến công việc (có giấy xác nhận của công ty), học tập (có xác nhận của trường học) dẫn đến việc không thể tham gia kiểm tra.</li></span>
    </p>
    <ol class=" font-bold" start="6">
      <li style="list-style-type:decimal">
        <h3 style="text-align:justify"><span><strong>Chính sách chuyển nhượng khóa học</strong></span></h3>
      </li>
    </ol>
    <p><span>- Khi có nhu cầu chuyển nhượng, Khách hàng đến trực tiếp Trung tâm hoặc liên hệ bộ phận có thẩm quyền (quản lý Khách hàng hoặc Trung tâm hỗ trợ) của Trung tâm để được hướng dẫn thủ tục.</span>
    </p>
    <p><span>- Khách hàng được quyền chuyển nhượng các khoá học khi hoàn tất thủ tục chuyển nhượng trước 01 ngày Khai giảng khóa học diễn ra.</span>
    </p>
    <p><span>- Khách hàng phải tìm người chuyển nhượng khóa học của mình để hoàn tất thủ tục chuyển nhượng.</span>
    </p>
    <p><span>- Khách hàng được chuyển nhượng 01 lần duy nhất/ khóa học hoặc nhóm khóa học (combo) và được chuyển nhượng cho 01 người duy nhất.</span>
    </p>
    <p><span>- Với trường hợp Khách hàng đã được xếp lớp, người được Khách hàng chuyển nhượng cần có trình độ đầu vào cùng với trình độ đầu vào của Khách hàng (thông qua bài thi kiểm tra đầu vào do IELTS Mentor tổ chức) và phải học ngay khóa học đã được xếp đó.</span>
    </p>
    <p><span>- Với trường hợp Khách hàng chưa được xếp lớp, người được Khách hàng chuyển nhượng có thể chênh lệch trình độ đầu vào.</span>
    </p>
    <p><span>     <li style="list-style-type:disc"> Trường hợp người được chuyển nhượng có trình độ đầu vào thấp hơn Khách hàng: Thông qua kết quả kiểm tra đầu vào do IELTS Mentor tổ chức, người được chuyển nhượng cần hoàn thành 100% phí dịch vụ và bắt đầu từ khóa học tương ứng với kết quả đầu vào thời điểm đó.</li></span>
    </p>
    <p><span>     <li style="list-style-type:disc"> Trường hợp người được chuyển nhượng có trình độ cao hơn Khách hàng: Thông qua kết quả kiểm tra đầu vào do IELTS Mentor tổ chức, người được chuyển nhượng cần bắt đầu từ khóa học tương ứng với kết quả đầu vào thời điểm đó.</li></span>
    </p>
    <p><span>- Khách hàng chỉ được quyền chuyển nhượng khi đã hoàn thành 100% phí dịch vụ với Trung tâm.</span>
    </p>
    <p><span>- Chính sách chuyển nhượng này không áp dụng cho các chương trình khuyến mãi đặc biệt vào các dịp khai trương và Flash sale.</span>
    </p>
    <ol class=" font-bold" start="7">
      <li style="list-style-type:decimal">
        <h3 style="text-align:justify"><span><strong>Chính sách bảo lưu</strong></span></h3>
      </li>
    </ol>
    <p><span>- Trung tâm hỗ trợ Khách hàng thực hiện việc bảo lưu khóa học xuyên suốt khóa học với điều kiện Khách hàng không nghỉ học 05 buổi liên tục (bao gồm có phép và không phép) và Khách hàng đã hoàn thành đủ 100% phí dịch vụ;</span>
    </p>
    <p><span>- Chi phí bảo lưu cho khóa học là 50% phí dịch vụ của khóa học theo mức giá gốc tại thời điểm Khách hàng đăng ký khóa học.</span>
    </p>
    <p><span>- Trong trường hợp đặc biệt: Khách hàng thực hiện việc bảo lưu vì điều kiện bất khả kháng, chi phí bảo lưu khóa học là 35% phí dịch vụ của khóa học theo mức giá gốc tại thời điểm Khách hàng đăng ký khóa học. Các trường hợp bất khả kháng bao gồm: có vấn đề sức khỏe, cần nằm viện liên tục nhiều hơn 20 ngày dẫn đến không thể tham gia lớp học, có giấy xác nhận của cơ quan y tế; hoặc Khách hàng nhận được lịch đi học quân sự từ Nhà trường (phải ở lại khu quân sự nhiều hơn 20 ngày) mà Khách hàng không biết trước (tính từ thời điểm Khách hàng được Trung tâm gọi xếp lớp tới thời điểm lớp khai giảng); có giấy xác nhận đi học quân sự của Nhà trường;
      </span>
    </p>
    <p><span>- Khách hàng chỉ được bảo lưu duy nhất một lần/ khóa học;
      </span>
    </p>
    <p><span>- Thời gian bảo lưu tối đa là 90 ngày liên tục. Sau thời gian trên, nếu Khách hàng không hoàn thành thủ tục để quay trở lại học, Trung tâm vẫn bảo lưu khóa học mà Khách hàng thực hiện bảo lưu, đồng thời, Khách hàng cần tham gia kiểm tra lại trình độ đầu vào (thông qua bài thi kiểm tra đầu vào do IELTS Mentor tổ chức). Trường hợp kết quả đầu vào thấp hơn so với trình độ đầu vào của khóa học được bảo lưu, Khách hàng cần hoàn thành 100% phí dịch vụ, bắt đầu học lại từ khóa học tương ứng với kết quả đầu vào thời điểm đó.
      </span>
    </p>
    <p><span>- Trong trường hợp Khách hàng tự ý nghỉ học 5 buổi liên tục mà không đến làm thủ tục bảo lưu hoặc không có thông báo với Trung tâm thì Khách hàng được coi là đơn phương chấm dứt học tập, đồng thời không được hưởng bất kỳ quyền lợi nào từ Trung tâm và đương nhiên không được nhận lại phí dịch vụ của khóa học đó. Nếu Khách hàng muốn tham gia học lại khóa học này, Khách hàng phải đóng đủ 100% phí dịch vụ của khóa học theo mức giá Khách hàng lẻ niêm yết tại thời điểm đó.
      </span>
    </p>
    <ol class=" font-bold" start="8">
      <li style="list-style-type:decimal">
        <h3 style="text-align:justify"><span><strong>Chính sách hoàn học phí</strong></span></h3>
      </li>
    </ol>
    <p class="font-bold" style="text-align:justify"><span><strong>8.1. Tiếp nhận nhu cầu</strong></span></p>
    <p><span>Để yêu cầu hoàn lại tiền, Khách hàng vui lòng liên hệ qua kênh IELTS Mentor Support Centre hoặc đến trực tiếp văn phòng giao dịch thuộc Hệ thống Trung tâm IELTS Mentor để được đội ngũ Quản lý tại điểm hỗ trợ.</span>
    </p>
    <p class="font-bold" style="text-align:justify"><span><strong>8.2. Điều kiện hoàn học phí</strong></span></p>
    <p><span>Khách hàng được hoàn trả phí dịch vụ trong các trường hợp (trước khóa học).</span>
    </p>
    <p class="font-bold" style="text-align:justify">
      <span><strong>8.2.1. Trường hợp hoàn tiền 65% khi:</strong></span></p>
    <p><span>- Khách hàng đi du học: Cần giấy xác nhận của nhà trường hoặc Thư xác nhận nhập học của trường Khách hàng chuẩn bị du học;</span>
    <p><span>- Thay đổi địa điểm sinh sống ra nước ngoài: Cần giấy tạm trú, tạm vắng.</span>
    </p>
    <p class="font-bold" style="text-align:justify">
      <span><strong>8.2.2. Trường hợp hoàn tiền 100% khi:</strong></span></p>
    <p><span>- Trung tâm không mở được khóa học mà Khách hàng đã đăng ký trước đó trong vòng 6 tháng bắt đầu từ thời điểm Khách hàng hoàn thành đóng học phí (với khóa học đầu tiên) hoặc từ thời điểm kết thúc khóa học liền kề với khóa học mới.</span>
    </p>
    <p><span>- Bệnh hiểm nghèo theo quy định của bảo hiểm nhà nước (Nghị định 134/2015/NĐ-CP của Chính phủ quy định chi tiết một số điều của Luật Bảo hiểm xã hội về bảo hiểm xã hội tự nguyện).</span>
    </p>
    <p><span>- Trường hợp IELTS Mentor và các trung tâm đối tác không mở được khóa trong vòng 06 tháng đó, hoàn tiền trong phạm vi từ khóa học không mở được đến các khóa học tiếp theo trong nhóm khóa học.</span>
    </p>
    <p class="font-bold" style="text-align:justify"><span><strong>8.3. Hồ sơ hoàn học phí</strong></span></p>
    <p><span>- Hồ sơ hoàn trả phí dịch vụ: </span>
    </p>
    <p><span>     <li style="list-style-type:disc"> Phiếu đăng ký học tập tại IELTS Mentor;</li></span></p>
    <p><span>     <li style="list-style-type:disc"> Chứng minh nhân dân hoặc căn cước công dân của Khách hàng. Số lượng: 1 (một) bản photo;</li></span>
    </p>
    <p><span>     <li style="list-style-type:disc"> Giấy chứng nhận tương ứng với các lý do (Chi tiết từng trường hợp theo mục 8.2.1 và 8.2.2).</li></span>
    </p>
    <p><span>- Số tiền hoàn = Học phí thực đóng - Tổng chi phí khóa mà Khách hàng đã học (nếu có) - Quà tặng Khách hàng nhận theo giá niêm yết trên chương trình khuyến mãi của thời điểm Khách hàng đăng ký học (nếu có).</span>
    </p>
    <p class="font-bold" style="text-align:justify"><span><strong>8.4. Thời gian hoàn học phí</strong></span></p>
    <p><span>- Thời gian xử lý phản hồi hoàn học phí: Trong vòng 48h kể từ thời điểm tiếp nhận và nhận đầy đủ hồ sơ của Khách hàng (trừ các ngày Chủ nhật, Lễ, Tết theo quy định của Nhà nước);</span>
    </p>
    <p>
      <span>- Thời gian hoàn lại học phí: Thứ 3 và Thứ 6 hàng tuần (trừ các ngày Chủ nhật, Lễ, Tết theo quy định của Nhà nước).</span>
    </p>
    <p class="font-bold" style="text-align:justify"><span><strong>8.5 Hình thức hoàn học phí</strong></span></p>
    <p>IELTS Mentor chỉ hỗ trợ hoàn trả phí qua hình thức chuyển khoản. Sau khi được liên hệ thông báo về việc đồng ý
      duyệt trả học phí thì Khách hàng vui lòng liên hệ với kênh tiếp nhận hoàn học phí tại mục 8.1. Tiếp nhận nhu cầu
      của mục 8. Chính sách hoàn học phí và cung cấp đầy đủ các thông tin sau: Tên ngân hàng, Số tài khoản, Tên chủ tài
      khoản để chuyển khoản.
    </p>
    <p>Tên chủ tài khoản phải khớp với tên Khách hàng. Trường hợp Khách hàng dưới 15 tuổi, Số tài khoản nhận hoàn học
      phí
      có thể là của Bố/Mẹ hoặc người giám hộ (Thông tin người giám hộ này cần trùng khớp thông tin người giám hộ tại
      phiếu đăng ký học tập của IELTS Mentor)</p>
    <p class="font-italic">Xin lưu ý rằng, nếu IELTS Mentor phát hiện Khách hàng đang lợi dụng chính sách hoàn trả của
      chúng tôi, chúng tôi
      có quyền từ chối xử lý hoặc hạn chế bất kỳ hay tất cả các quyền lợi học tập trong hiện tại và tương lai của Khách
      hàng tại IELTS Mentor, mà không chịu bất kỳ trách nhiệm pháp lý nào.</p>

    <ol class=" font-bold" start="9">
      <li style="list-style-type:decimal">
        <h3 style="text-align:justify"><span><strong>Chính sách hoàn học phí</strong></span></h3>
      </li>
    </ol>

    <p>- Sự kiện bất khả kháng là sự kiện xảy ra mang tính chất khách quan và nằm ngoài tầm kiểm soát của các bên (thiên
      tai, hỏa hoạn, lũ lụt,…) và sự thay đổi chính sách hoặc ngăn cấm của cơ quan có thẩm quyền của Nhà nước Việt
      Nam.</p>
    <p>- Việc một bên không hoàn thành nghĩa vụ của mình do sự kiện bất khả kháng sẽ không phải là cơ sở để bên kia chấm
      dứt Điều khoản dịch vụ và không bị phạt/ bồi thường Điều khoản dịch vụ khi điều chỉnh hoặc hủy dịch vụ. Tuy nhiên
      bên bị ảnh hưởng bởi sự kiện bất khả kháng có nghĩa vụ phải:</p>
    <p>     + Tiến hành các biện pháp ngăn ngừa hợp lý và các biện pháp thay thế cần thiết để hạn chế tối đa ảnh hưởng
      do sự kiện bất khả kháng gây ra.</p>
    <p>     + Thông báo ngay cho bên kia về sự kiện bất khả kháng và nếu bên B muốn điều chỉnh hoặc hủy dịch vụ hoặc
      thay đổi thời gian sử dụng dịch vụ phải thông báo cho bên A trước 48 giờ.</p>
    <p>     + Trong trường hợp xảy ra sự kiện bất khả kháng, thời gian thực hiện Điều khoản dịch vụ sẽ được kéo dài bằng
      thời gian diễn ra sự kiện bất khả kháng mà bên bị ảnh hưởng không thể thực hiện các nghĩa vụ theo Điều khoản dịch
      vụ của mình.</p>
    <ol class=" font-bold" start="10">
      <li style="list-style-type:decimal">
        <h3 style="text-align:justify"><span><strong>Các thay đổi</strong></span></h3>
      </li>
    </ol>
    <p>Điều khoản dịch vụ của IELTS MENTOR có thể sẽ thay đổi trong từng thời điểm để phục vụ việc nâng cấp trải nhiệm
      Khách hàng. Trung tâm sẽ công bố rõ ràng bất kỳ sự thay đổi nào về Điều khoản dịch vụ trên Website hoặc các kênh
      truyền thông chính thức khác của IELTS MENTOR, và thông báo nổi bật với những thay đổi quan trọng. Đồng thời,
      Khách hàng sẽ thực hiện theo những Điều khoản dịch vụ được thay đổi đó của Trung tâm, kể từ thời điểm Trung tâm
      công bố trên Website hoặc các kênh truyền thông chính thức khác.
    </p>
    <ol class="font-bold" start="11">
      <li style="list-style-type:decimal">
        <h3 style="text-align:justify"><span><strong>Luật áp dụng và giải quyết tranh chấp</strong></span></h3>
      </li>
    </ol>
    <p>Khi phát sinh tranh chấp, Trung tâm và Khách hàng cùng thống nhất áp dụng pháp luật Việt Nam để giải quyết;
    </p>
    <p>Mọi tranh chấp phát sinh liên quan đến Điều khoản dịch vụ này trước hết sẽ được giải quyết thông qua thương lượng
      và hòa giải giữa các bên;</p>
    <p>Trong trường hợp hòa giải không thành thì một trong các bên có quyền yêu cầu Tòa án có thẩm quyền giải quyết;</p>
    <p>Các nội dung chưa được quy định tại Điều khoản dịch vụ sẽ được thực hiện theo các quy định của Pháp luật, bao gồm
      cả quyền và trách nhiệm của các bên.</p>
    <p class="font-weight-light font-weight-bold">Trong trường hợp gặp các sự cố không mong muốn hoặc có vấn đề cần phản
      ánh, Khách hàng
      có thể liên hệ tới Tổng đài 1900 4455 để được hỗ trợ.</p>
  </div>
</template>

<script>
export default {
  name: "ieltsmentor_collapse_chinh_sach_hoc_tap"
}
</script>

<style scoped>
li::marker {
  font-size: 15px;
}
</style>