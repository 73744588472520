<template>
  <div class="pl-4 pt-4 font-light border border-t-0 border-gray-200 dark:border-gray-700 accordion-item">
    <ol class=" font-bold">
      <li style="list-style-type:decimal">
        <h4 style="text-align:justify">
          <span><strong>QUY ĐỊNH VỀ HÌNH THỨC THANH TOÁN</strong></span></h4>
      </li>
    </ol>
    <ol class="font-bold" start="1">
      <li style="list-style-type:decimal">
        <h4 style="text-align:justify"><span><strong>Nộp học phí trực tiếp bằng tiền mặt tại hệ thống Trung tâm IELTS Mentor</strong></span>
        </h4>
      </li>
    </ol>
    <p style="text-align:justify"><span>Khi đến văn phòng giao dịch thuộc Hệ thống Trung tâm IELTS Mentor (sau đây viết tắt là IELTS Mentor) thực hiện việc thanh toán, Khách hàng sẽ được nhân viên IELTS Mentor hướng dẫn các bước thanh toán. </span>
    </p>

    <p style="text-align:justify"><span>Thời gian nhận thanh toán của Khách hàng bằng tiền mặt: từ 14h00 đến 21h00 các ngày từ thứ 2 tới thứ 7 hàng tuần (trừ ngày lễ, tết theo quy định Nhà nước).</span>
    </p>

    <ol class=" font-bold" start="2">
      <li style="list-style-type:decimal">
        <h4 style="text-align:justify"><span><strong>Thanh toán chuyển khoản</strong></span></h4>
      </li>
    </ol>

    <p class="font-bold" style="text-align:justify"><span><strong>2.1. Chuyển tiền/ chuyển khoản theo thông tin chi tiết IELTS Mentor cung cấp:</strong></span>
    </p>

    <div>
      <p><span>- Ngân hàng thương mại cổ phần Quân đội </span><span class="font-bold"><strong>MBbank</strong></span></p>
      <p><span class="font-bold"><strong>- Chủ tài khoản</strong></span><span>: Hồ Văn Hoàn</span></p>
      <p><span class="font-bold"><strong>- Số tài khoản</strong></span><span>: 4666999666999</span></p>
      <p><span class="font-bold"><strong>- Nội dung chuyển khoản</strong></span><span>: Số điện thoại  Họ và Tên Khách hàng</span>
      </p>
    </div>

    <p class="font-bold italic" style="text-align:justify">
      <span><strong><em>Ví dụ: 0123456789 Nguyen Van A</em></strong></span></p>

    <p style="text-align:justify"><span>Số điện thoại trong Nội dung chuyển khoản sẽ được sử dụng để xác nhận thông tin chuyển khoản và gọi điện xếp lớp.</span>
    </p>

    <p class="font-bold" style="text-align:justify"><span><strong>2.2. Hoàn tất thông tin sau tin nhắn xác nhận chuyển tiền</strong></span>
    </p>

    <p style="text-align:justify">
      <span>
      IELTS Mentor sẽ nhắn tin tới số điện thoại Khách hàng cung cấp trong Nội dung chuyển khoản để xác nhận thông tin chuyển tiền với Khách hàng muộn nhất trong vòng 4h (theo giờ hành chính) sau khi nhận được tiền. Trường hợp không nhận được tin nhắn, Khách hàng vui lòng liên hệ qua IELTS Mentor Support Centre hoặc gọi Tổng đài 1900 4455 để được xử lý thông tin kịp thời.
    </span>
    </p>

    <p style="text-align:justify"><span>IELTS Mentor không chịu trách nhiệm nếu trong quá trình chuyển khoản sai thông tin. Trong một số tình huống cụ thể, Khách hàng có thể nhờ phía Ngân hàng thực hiện chuyển khoản hoặc Ngân hàng thụ hưởng của IELTS Mentor để kiểm tra đối chứng cần thiết. Ngay khi nhận được xác nhận từ Ngân hàng, IELTS Mentor sẽ tiến hành thủ tục tiếp theo cho Khách hàng.</span>
    </p>

    <ol class=" font-bold" start="3">
      <li style="list-style-type:decimal">
        <h4 style="text-align:justify"><span><strong>Thanh toán qua thẻ</strong></span></h4>
      </li>
    </ol>

    <p style="text-align:justify"><span>Khi đến văn phòng giao dịch thuộc Hệ thống Trung tâm IELTS Mentor (sau đây viết tắt là IELTS Mentor) thực hiện việc thanh toán, Khách hàng sẽ được nhân viên IELTS Mentor hướng dẫn các bước thanh toán qua thẻ. </span>
    </p>
    <p style="text-align:justify"><span>Thời gian nhận thanh toán Khách hàng hình thức quẹt thẻ: 14h00 - 21h00 từ thứ 2 tới thứ 7 hàng tuần (trừ ngày lễ, tết theo quy định Nhà nước).</span>
    </p>

    <ol class=" font-bold" start="4">
      <li style="list-style-type:decimal">
        <h4 style="text-align:justify"><span><strong>Lưu ý sau khi thanh toán</strong></span></h4>
      </li>
    </ol>

    <p><span>- IELTS Mentor không chấp nhận và hỗ trợ xử lý các trường hợp nếu Khách hàng thanh toán bằng hình thức khác với 3 (ba) hình thức trong mục </span><a
        href="javascript:void(0)"
        style="text-decoration:none"><span
        class="font-bold"><strong><u>QUY ĐỊNH VỀ HÌNH THỨC THANH TOÁN</u></strong></span></a><span><strong> </strong></span><span> của điều khoản này.</span>
    </p>
    <p><span>- Khách hàng sẽ được cung cấp:</span></p>
    <p class="pl-5"><span class="font-bold"><strong>+ Phiếu đăng ký học tập tại IELTS Mentor: </strong></span><span>Sau khi đăng ký học tập thành công;</span>
    </p>
    <p class="pl-5"><span class="font-bold"><strong>+ Hóa đơn:</strong></span><span> Nhận qua gmail Khách hàng đăng ký Trung tâm (nếu Khách hàng thanh toán không tại Trung tâm) hoặc trực tiếp tại Trung tâm IELTS Mentor sau mỗi lần Khách hàng thanh toán thành công.</span>
    </p>
    <p><span class="font-bold"><strong>- Hóa đơn và Phiếu đăng ký học tập tại IELTS Mentor</strong></span><span> được sử dụng để giải quyết các vấn đề liên quan đến quyền lợi trong quá trình học tập bao gồm:</span>
    </p>
    <p class="pl-5"><span>+ Xếp lớp học theo khóa học, thời gian học đã đăng ký</span></p>
    <p class="pl-5"><span>+ Chuyển nhượng khóa học (nếu có);</span></p>
    <p class="pl-5"><span>+ Hoàn học phí (nếu có);</span></p>
    <p class="pl-5"><span>+ Bảo lưu kết quả học tập (nếu có);</span></p>
    <p class="pl-5"><span>+ Bảo hành kết quả học tập.</span></p>
    <p>Khách hàng nhận hồ sơ và có trách nhiệm lưu giữ. Trường hợp không nhận được Hóa đơn và Phiếu đăng ký học tập tại
      IELTS Mentor, vui lòng liên hệ trực tiếp qua kênh IELTS Mentor Support Centre hoặc Tổng đài <strong>1900
        4455</strong> để được hỗ
      trợ.</p>
  </div>
</template>

<script>
export default {
  name: "ieltsmentor_collapse_hinh_thuc_thanh_toan"
}
</script>

<style scoped>
li::marker {
  font-size: 15px;
}
</style>